<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title>HCPI Sperre setzen</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right">Test</ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
        <ion-label position="stacked">Letzte Änderung</ion-label>
        <ion-datetime readonly v-model="scoringRecord.hcpiLockUpdated"></ion-datetime>
        <ion-datetime readonly display-format="HH:mm" picker-format="HH:mm" v-model="scoringRecord.hcpiLockUpdated"></ion-datetime>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">HCPI Sperre aktiv</ion-label>
        <ion-select :value="String(hcpiLockActive)" @ionChange="hcpiLockActive = $event.target.value">
          <ion-select-option value="true">Ja</ion-select-option>
          <ion-select-option value="false">Nein</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('comment') }}</ion-label>
        <ion-input type="text" v-model="hcpiLockComment"></ion-input>
      </ion-item>
      <ion-button @click="setHcpiLock()" expand="block" color="success">{{ $t('save') }}</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonDatetime, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, closeCircleOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'
import setHcpiLockMutationFile from '../graphql/setHcpiLockMutation.mutation.gql'

export default defineComponent({
  name: 'setHcpiLockDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonDatetime, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonSelect, IonSelectOption },
  props: ['propsData'],
  data() {
    return {
      scoringRecord: this.propsData.scoringRecord,
      hcpiLockActive: this.propsData.scoringRecord.hcpiLock,
      hcpiLockComment: this.propsData.scoringRecord.hcpiLockComment,
    }
  },
  mounted(){
    //console.log(this.propsData)
    //console.log(this.hcpiLockActive)
  },
  setup() {
    const { mutate: setHcpiLockMutation } = useMutation(setHcpiLockMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline,
      closeCircleOutline,
      setHcpiLockMutation,
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss();
    },
    setHcpiLock() {
      this.setHcpiLockMutation({         
        dgvPlayerId: this.scoringRecord.playerId,
        hcpiLock: this.hcpiLockActive === 'true',
        hcpiLockComment: this.hcpiLockComment,
        })
        .then(res => {
          toastController
            .create({
              message: "HCPI Sperre erfolgreich aktualisert",
              duration: 2000,
              color: 'success'
            }).then(res => res.present())
          this.closeDialog()
        })
        .catch(err => {
          toastController
            .create({
              message: "Beim speichern der HCPI Sperre ist ein Fehler aufgetreten",
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        })
    }

  }
});
</script>