<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>HCPI Change</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <section>
        <ion-list>
          <ion-item>
              <ion-chip outline color="successlight" @click="addUser()">
                <ion-icon style="height: 50px; margin: 0px;" :icon="addOutline"></ion-icon>
              </ion-chip>
          </ion-item>
          <ion-item v-for="(user, i) in usersFound" :key="i">
            <ion-label>
              <h2>{{ user.lastName }} {{ user.firstName }}</h2>
              <h3>{{ user.email}}</h3>
            </ion-label>
              <ion-toggle slot="start" name="blueberry" :checked="user.enabled" color="success" @ionChange="changeState(user._id, $event.detail.checked)"></ion-toggle>
              <ion-chip outline color="danger" @click="presentAlertConfirm(user)">
                <ion-icon style="height: 50px; margin: 0px;" :icon="trashOutline"></ion-icon>
              </ion-chip>
          </ion-item>
        </ion-list>
      </section>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, 
          IonCardHeader, IonCardContent, IonCard, IonToggle, IonIcon, IonChip, IonList, IonItem,
          modalController, toastController, alertController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import { trashOutline, optionsOutline, addOutline } from 'ionicons/icons';
import findUsersQueryFile from '../graphql/findUsers.query.gql'
import changeStatusUserMutationFile from '../graphql/changeStatusUser.mutation.gql'
import deleteUserMutationFile from '../graphql/deleteUser.mutation.gql'
import AddUserDialog from './AddUserDialog'

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, alertController,
    IonButton, IonLabel, IonCardHeader, IonCardContent, IonCard, IonToggle, IonIcon, IonChip, IonList, IonItem,
  },
  setup() {
    const {refetch: findUsersRefetch, result: findUsersResult, query: findUsersQuery } = useQuery(findUsersQueryFile, {searchTerm: ''}, { fetchPolicy: 'no-cache' })
    const usersFound = useResult(findUsersResult, null, data => data.findUsers.users)
    
    const { mutate: changeStatusUserMutation } = useMutation(changeStatusUserMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: deleteUserMutation } = useMutation(deleteUserMutationFile, { fetchPolicy: 'no-cache' });

return {
      usersFound,
      trashOutline, optionsOutline, addOutline,
      changeStatusUserMutation, deleteUserMutation, findUsersRefetch
    }
  },
  data() {
    return {
      imageUrl: null
    }
  },
  computed: {
      selectedPerson : function(){ return this.$store.getters.selectedPerson},
  },
  methods: {
    async addUser() {
      const modal = await modalController
        .create({
          component: AddUserDialog,
        })

      modal.onDidDismiss()
      .then((data) => {
        this.findUsersRefetch({searchTerm: ''})
      })

      return await modal.present()
    },
    async presentAlertConfirm(user) {
      const alert = await alertController
        .create({
          header: this.$t('deleteUser') +  ' ' + user.lastName + ' ' + user.firstName,
          message: this.$t('deleteUserAsk') + '<strong>' + user.lastName + ' ' + user.firstName + '</strong>?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Ok',
              handler: () => {
                this.deleteUserMutation({
                  userId: user._id
                })
                .then(res => {
                  this.findUsersRefetch({searchTerm: ''})
                })
              },
            },
          ],
        });
      return alert.present();
    },
    changeState(userId, status) { 
        this.changeStatusUserMutation({
          userId: userId, status: status
        })
        .then(res => {
          this.findUsersRefetch({searchTerm: ''})
        })

    },

  }
});

</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}
</style>