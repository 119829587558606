<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title v-if="scoringRecordItem">{{ scoringRecordItem.id }}</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right">Test</ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card v-if="scoringRecordPreviewResultData">
    <ion-card-header>
      <ion-card-title>WHS</ion-card-title>
    </ion-card-header>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
            <ion-item>
              <ion-label>Score Differential Pre PCC</ion-label>
              <ion-badge slot="end">{{ scoringRecordPreviewResultData.score_differential_pre_pcc }}</ion-badge>
            </ion-item>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-item>
                <ion-label>Score Differential Post PCC</ion-label>
                <ion-badge slot="end">{{ scoringRecordPreviewResultData.score_differential_post_pcc }}</ion-badge>
              </ion-item>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div>
            <ion-item>
              <ion-label>Score Differential</ion-label>
              <ion-badge slot="end">{{ scoringRecordPreviewResultData.score_differential }}</ion-badge>
            </ion-item>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-item>
                <ion-label>PCC</ion-label>
                <ion-badge slot="end">{{ scoringRecordPreviewResultData.pcc }}</ion-badge>
              </ion-item>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div>
            <ion-item>
              <ion-label>Exceptional Score</ion-label>
              <ion-badge slot="end">{{ scoringRecordPreviewResultData.exceptional_score }}</ion-badge>
            </ion-item>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-item>
                <ion-label>Exceptional Score Reduction</ion-label>
                <ion-badge slot="end">{{ scoringRecordPreviewResultData.exceptional_score_reduction }}</ion-badge>
              </ion-item>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
    <ion-card v-else>
      <ion-card-header>Dieser Eintrag wurde noch nicht am WHS API gerechnet, oder fällt aktuell nicht unter die 20 letzten Einträge im Scoringrecord</ion-card-header>
    </ion-card>
    <ion-list>
      <ion-list-header>
        <ion-chip outline @click="loadTemplateData()">
          <ion-icon :icon="downloadOutline"></ion-icon>
          <ion-label>Anker Eintrag laden</ion-label>
        </ion-chip>
        <ion-chip outline @click="loadTemplateData(2)">
          <ion-label>18 Loch</ion-label>
        </ion-chip>
        <ion-chip outline @click="loadTemplateData(3)">
          <ion-label>9 Loch</ion-label>
        </ion-chip>
        <ion-chip outline @click="getScoringRecordPreviewMethod(scoringRecordItem)">
          <ion-icon :icon="refreshOutline"></ion-icon>
          <ion-label>WHS Daten Refresh</ion-label>
        </ion-chip>
      </ion-list-header>
      <ion-item style="border-color: red;">
        <ion-label position="stacked">{{ $t('date') }}</ion-label>
        <ion-datetime v-model="scoringRecordItem.date"></ion-datetime>
        <ion-datetime display-format="HH:mm" picker-format="HH:mm" v-model="scoringRecordItem.date"></ion-datetime>
        <ion-note color="danger" v-if="!validators.validateDateTime(scoringRecordItem.date)">Test</ion-note>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('tournamentName') }}</ion-label>
        <ion-input type="text" v-model="scoringRecordItem.tournamentName"></ion-input>
        <ion-note color="danger" v-if="!validators.validateTournamentName(scoringRecordItem.tournamentName)">Test</ion-note>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('round') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.round"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('tournamentClub') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.tournamentClub"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('par') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.par"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('courseRating') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.courseRating"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('slopeRating') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.slopeRating"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('playForm') }}</ion-label>
        <ion-select :value="scoringRecordItem.playForm" @ionChange="scoringRecordItem.playForm = $event.target.value">
          <!--  -->
          <ion-select-option value="S">Stableford</ion-select-option>
          <ion-select-option value="P">Gegen Par</ion-select-option>
          <ion-select-option value="Z">Zählspiel</ion-select-option>
          <ion-select-option value="L">Lochspiel</ion-select-option>
          <ion-select-option value="G">Stableford - Gegen Par / Gemischt</ion-select-option>
          <ion-select-option value="F">Freier Modus</ion-select-option>
          <ion-select-option value="H">Höchstergebis</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('tournamentType') }}</ion-label>
        <ion-select :value="String(scoringRecordItem.tournamentType)" @ionChange="scoringRecordItem.tournamentType = parseInt($event.target.value)">
          <!--  -->
          <ion-select-option value="0">Änderungseintrag, Ersteintrag</ion-select-option>
          <ion-select-option value="1">Einzel</ion-select-option>
          <ion-select-option value="2">Klassischer Vierer</ion-select-option>
          <ion-select-option value="3">Vierer mit Auswahltreibschlag</ion-select-option>
          <ion-select-option value="4">Chapman Vierer</ion-select-option>
          <ion-select-option value="5">Vierball</ion-select-option>
          <ion-select-option value="6">Aggregat Vierer</ion-select-option>
          <ion-select-option value="7">Scramble</ion-select-option>
          <ion-select-option value="8">EDS</ion-select-option>
          <ion-select-option value="9">Mannschafsturnier</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('holes') }}</ion-label>
        <ion-select :value="String(scoringRecordItem.holes)" @ionChange="scoringRecordItem.holes = $event.target.value">
          <ion-select-option value="18">18</ion-select-option>
          <ion-select-option value="9">9</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item v-if="new Date(scoringRecordItem.date) > new Date('2020-11-23')">
        <ion-label position="stacked">{{ $t('adjustedGrossScore') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.adjustedGrossScore"></ion-input>
      </ion-item>
      <ion-item v-else-if="new Date(scoringRecordItem.date) < new Date('2020-11-23')">
        <ion-label position="stacked">{{ $t('snpv') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.snpv"></ion-input>
      </ion-item>
      <ion-item v-else>
        <ion-label position="stacked">Bitte ein Datum auswählen bevor SNPV oder GBE gesetzt werden können.</ion-label>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('hcpi') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.hcpi"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('playingHcpi') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.playingHcpi"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('PCC') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.pcc"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('comment') }}</ion-label>
        <ion-input type="text" v-model="scoringRecordItem.comment"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('ignoreExceptionalScoreAdjustment') }}</ion-label>
        <ion-select :value="scoringRecordItem.ignoreExceptionalScoreAdjustment" @ionChange="scoringRecordItem.ignoreExceptionalScoreAdjustment = $event.target.value">
          <ion-select-option value="true">Ja</ion-select-option>
          <ion-select-option value="false">Nein</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('resultStatus') }}</ion-label>
        <ion-select :value="scoringRecordItem.resultStatus" @ionChange="scoringRecordItem.resultStatus = $event.target.value">
          <ion-select-option value="W">W (Handicap Wirksam)</ion-select-option>
          <ion-select-option value="AK">AK (Außer Kokurrenz)</ion-select-option>
          <ion-select-option value="NA">NA (Nicht Angetreten)</ion-select-option>
          <ion-select-option value="TA">TA (Turnierabbruch)</ion-select-option>
          <ion-select-option value="NRA">NRA (No Return Annerkannter Grund)</ion-select-option>
          <ion-select-option value="NRO">NRO (No Return Ohne Annerkannter Grund)</ion-select-option>
          <ion-select-option value="DQA">DQA (Disqualifizert Anerkanner Grund)</ion-select-option>
          <ion-select-option value="DQO">DQO (Disqualifizert Ohne Annerkannter Grund)</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('country') }}</ion-label>
        <ion-input required="true" type="text" v-model="scoringRecordItem.country"></ion-input>
      </ion-item>
      <ion-button @click="saveScoringRecordItem(scoringRecordItem)" expand="block" color="success">{{ $t('save') }}</ion-button>
      <ion-button @click="saveScoringRecordItem(true)" expand="block" color="warning">{{ $t('reset') }}</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonDatetime, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController, alertController, IonCardTitle, IonCardHeader, IonBadge, IonCard, IonChip, IonListHeader, IonNote, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, closeCircleOutline, refreshOutline, downloadOutline, calendarOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'
import addUserMutationFile from '../graphql/addUser.mutation.gql'

import * as dayjs from 'dayjs';

import * as validators from '../plugins/validators'
import { valid } from 'joi';

export default defineComponent({
  name: 'AddUserDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonDatetime, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonCardTitle, IonCardHeader, IonBadge, IonCard, IonChip, IonListHeader, IonNote, IonSelect, IonSelectOption,  },
  props: ['propsData'],
  data() {
    return {
      validators: validators,
      getScoringRecordPreview: this.propsData.getScoringRecordPreview,
      scoringRecordPreviewResultData: undefined,
      scoringRecordItem: { 
        date: undefined,
        tournamentName: undefined,
        round: undefined,
        tournamentClub: undefined,
        par: undefined,
        courseRating: undefined,
        slopeRating: undefined,
        holes: undefined,
        playForm: undefined,
        tournamentType: undefined,
        adjustedGrossScore: undefined,
        snpv: undefined,
        hcpi: undefined,
        playingHcpi: undefined,
        pcc: undefined,
        comment: undefined,
        ignoreExceptionalScoreAdjustment: undefined,
        resultStatus: undefined,
        country: undefined,
      }
    }
  },
  setup() {
    return {
      closeOutline,
      closeCircleOutline,
      refreshOutline, downloadOutline, calendarOutline
    }
  },
  methods: {
    loadTemplateData(templateNr) {
      if (templateNr == 1) {
        this.scoringRecordItem.date = new Date('2017-01-01').toISOString()
        return
      } else if (templateNr == 2) {
      this.scoringRecordItem.ignoreExceptionalScoreAdjustment = 'false'

      this.scoringRecordItem.date = new Date().toISOString()
      this.scoringRecordItem.round = 1
      this.scoringRecordItem.par = 72
      this.scoringRecordItem.courseRating = 72.0
      this.scoringRecordItem.slopeRating = 113
      this.scoringRecordItem.holes = 18
      this.scoringRecordItem.pcc = 0
      this.scoringRecordItem.playForm = "S"
      this.scoringRecordItem.resultStatus = "W"

      this.scoringRecordItem.country = 49
      this.scoringRecordItem.tournamentType = 1
      return
      } else if (templateNr == 3) {
      this.scoringRecordItem.ignoreExceptionalScoreAdjustment = 'false'

      this.scoringRecordItem.date = new Date().toISOString()
      this.scoringRecordItem.round = 1
      this.scoringRecordItem.par = 72
      this.scoringRecordItem.courseRating = 72.0
      this.scoringRecordItem.slopeRating = 113
      this.scoringRecordItem.holes = 9
      this.scoringRecordItem.pcc = 0
      this.scoringRecordItem.playForm = "S"
      this.scoringRecordItem.resultStatus = "W"

      this.scoringRecordItem.country = 49
      this.scoringRecordItem.tournamentType = 1
      return
      }

      this.scoringRecordItem.ignoreExceptionalScoreAdjustment = 'false'

      this.scoringRecordItem.date = new Date().toISOString()
      this.scoringRecordItem.round = 1
      this.scoringRecordItem.par = 72
      this.scoringRecordItem.courseRating = 72.0
      this.scoringRecordItem.slopeRating = 113
      this.scoringRecordItem.holes = 18
      this.scoringRecordItem.pcc = 0
      this.scoringRecordItem.playForm = "S"
      this.scoringRecordItem.resultStatus = "W"

      this.scoringRecordItem.country = 49
    },
    closeDialog() {
      return modalController.dismiss();
    },
    async saveScoringRecordItem(scoringRecordItem) {
      //console.log(scoringRecordItem)
      for (const [key, value] of Object.entries(scoringRecordItem)) {
        const nonRequiredFields = ['comment']
        if ((value === undefined || value === "") && !nonRequiredFields.includes(key)) {
          if ((key === 'adjustedGrossScore' || key === 'snpv') && !((scoringRecordItem.adjustedGrossScore === undefined || scoringRecordItem.adjustedGrossScore === '') && (scoringRecordItem.snpv === undefined || scoringRecordItem.snpv === '')) ) {
            return modalController.dismiss(scoringRecordItem);
          }
          const alert = await alertController
            .create({
              message: 'Bitte alle Felder ausfüllen',
              buttons: ['OK'],
            });
           return alert.present();
        }

        if (new Date(scoringRecordItem.date) < new Date('2020-11-23')) {
          scoringRecordItem.adjustedGrossScore = undefined
        } else {
          scoringRecordItem.snpv = undefined
        }
      }
      return modalController.dismiss(scoringRecordItem);
    },
    async getScoringRecordPreviewMethod(scoringRecordItem) {
       const result = await this.getScoringRecordPreview({
          scorecard: "preview",
          date: String( new Date(scoringRecordItem.date).toISOString() ),
          par: parseInt(scoringRecordItem.par),
          cr: parseFloat(scoringRecordItem.courseRating),
          slope: parseInt(scoringRecordItem.slopeRating),
          result: parseFloat(scoringRecordItem.adjustedGrossScore),
          result_type: 'gross',
          hcpi: parseFloat(scoringRecordItem.hcpi),
          ignore_exceptional_score_adjustment: scoringRecordItem.ignoreExceptionalScoreAdjustment === 'true',
          //hcpi_relevant is always true because only hcpi relevant scores enter the scoring Record
          hcpi_relevant: true
      })


      this.scoringRecordPreviewResultData = result.getScoringRecordPreview.scoringRecordItems.find(item => item.scorecard == 'preview')
    }
  }
});
</script>

<style scoped>
.item-required { --border-color: danger; }
</style>