<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>HCPI Change</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <section>
        <ion-label v-if="!selectedPerson && isLoggedIn">
          {{ $t('selectPerson') }}
        </ion-label>
        <ion-label v-else-if="!isLoggedIn">
          {{ $t('pleaseLogin') }}
        </ion-label>
        <ion-list v-else>
        <ion-list-header lines="inset">
            <ion-label><h1>Scoring Record</h1></ion-label>
            <ion-chip v-if="true" color="danger" outline @click="openResetHcpiDialog()">
              <ion-label>Reset HCPI</ion-label>
            </ion-chip>
            <ion-chip v-if="true" color="warning" outline @click="openSetSDAdjustmentDialog()">
              <ion-label>SD Anpassung</ion-label>
            </ion-chip>
            <ion-chip v-if="true" color="success" outline @click="openSetLowHcpiDialog()">
              <ion-label>Low HCPI setzen</ion-label>
            </ion-chip>
            <ion-chip v-if="true" :color="hcpiBreakColor" outline @click="openSetHcpiBreakDialog()">
              <ion-label>HCPI Bremse setzen</ion-label>
            </ion-chip>
            <ion-chip v-if="true" :color="hcpiLockColor" outline @click="openSetHcpiLockDialog()">
              <ion-label>HCPI Sperre setzen</ion-label>
            </ion-chip>
            <ion-chip v-if="false" :color="proStatusColor" outline @click="getScoringRecordPreview()">
              <ion-label>Pro Status setzen</ion-label>
            </ion-chip>
            <ion-chip outline @click="getScoringRecordPreview()">
              <ion-icon :icon="refreshOutline"></ion-icon>
              <ion-label>WHS Daten Refresh</ion-label>
            </ion-chip>
            <ion-chip outline :color="proStatusColor" @click="getScoringRecord()">
              <ion-icon :icon="refreshOutline"></ion-icon>
              <ion-label>WHS Server rechnen</ion-label>
            </ion-chip>
         </ion-list-header>
          <ion-grid v-if="scoringRecord">
            <ion-row>
              <ion-col>
                <div>
                  <ion-card>
                    <ion-card-header>
                      <ion-card-subtitle>{{ $t('playerName') }}</ion-card-subtitle>
                      <ion-card-title @click="copyToClipboard(scoringRecord.playerName)">{{ scoringRecord.playerName }}</ion-card-title>
                    </ion-card-header>
                  </ion-card>
                </div>
              </ion-col>
              <ion-col>
                <div>
                  <ion-card>
                    <ion-card-header>
                      <ion-card-subtitle>{{ $t('playerId') }}</ion-card-subtitle>
                      <ion-card-title @click="copyToClipboard(scoringRecord.playerId)">{{ scoringRecord.playerId }}</ion-card-title>
                    </ion-card-header>
                  </ion-card>
                </div>
              </ion-col>
              <ion-col>
                <div>
                  <ion-card>
                    <ion-card-header>
                      <ion-card-subtitle>{{ $t('clubName') }}</ion-card-subtitle>
                      <ion-card-title @click="copyToClipboard(scoringRecord.homeClubName)">{{ scoringRecord.homeClubName }}</ion-card-title>
                    </ion-card-header>
                  </ion-card>
                </div>
              </ion-col>
              <ion-col>
                <div>
                  <ion-card>
                    <ion-card-header>
                      <ion-card-subtitle>{{ $t('clubId') }}</ion-card-subtitle>
                      <ion-card-title @click="copyToClipboard(scoringRecord.homeClubId)">{{ scoringRecord.homeClubId }}</ion-card-title>
                    </ion-card-header>
                  </ion-card>
                </div>
              </ion-col>
              <ion-col>
                <div>
                  <ion-card>
                    <ion-card-header>
                      <ion-card-subtitle>Low Hcpi</ion-card-subtitle>
                      <ion-card-title @click="copyToClipboard(scoringRecord.lowHcpi)">{{ scoringRecord.lowHcpi }}</ion-card-title>
                    </ion-card-header>
                  </ion-card>
                </div>
              </ion-col>
              <ion-col>
                <div>
                  <ion-card>
                    <ion-card-header>
                      <ion-card-subtitle>{{ $t('currentHCPI') }}</ion-card-subtitle>
                      <ion-card-title @click="copyToClipboard(scoringRecord.currentHcpi)">{{ Math.round(scoringRecord.currentHcpi * 10) / 10 }}</ion-card-title>
                    </ion-card-header>
                  </ion-card>
                </div>
              </ion-col>
              <ion-col>
                <div>
                  <ion-card>
                    <ion-card-header>
                      <ion-card-subtitle>{{ $t('calculatesHCPI') }}</ion-card-subtitle>
                      <ion-card-title @click="copyToClipboard(scoringRecord.calculatedHcpi)">{{ scoringRecord.calculatedHcpi }}</ion-card-title>
                    </ion-card-header>
                  </ion-card>
                </div>
              </ion-col>
              <ion-col v-if="scoringRecordPreviewResultData">
                <div>
                  <ion-card :color="getScoreDifferentialWhsAPIColor(scoringRecordPreviewResultData.hcpi, scoringRecord.currentHcpi)">
                    <ion-card-header>
                      <ion-card-subtitle>WHS Vorschau HCPI</ion-card-subtitle>
                      <ion-card-title @click="copyToClipboard(scoringRecordPreviewResultData.hcpi)">{{ scoringRecordPreviewResultData.hcpi }}</ion-card-title>
                    </ion-card-header>
                  </ion-card>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="full" color="success" @click="saveScoringRecord()">Speichern</ion-button>
          
          <!-- Start Column Dropdown -->
          <ion-item v-show="false">
            <ion-label>{{ $t('columnLabel') }}</ion-label>
            <ion-select ref="selectedColumnsDropdown" selectedText=" " multiple="true" :value="enabledColumsChangeHCPI" @ionChange="setEnabledColumsChangeHCPI($event.target.value)">
              <ion-select-option v-for="(c, i) in columsChangeHCPI" :key="i" :value="c.name">{{ $t(c.name) }}</ion-select-option>
            </ion-select>
          </ion-item>
          <!-- End Column Dropdown -->

          <ion-item>
            <ion-label v-for="item in enabledColumsChangeHCPI" :key="item"><h3><strong>{{ $t(item) }}</strong></h3></ion-label>
            <ion-chip outline color="primary" @click="openSelectedColumnsDropdown(user)">
              <ion-icon style="height: 50px; margin: 0px;" :icon="reorderFourOutline"></ion-icon>
            </ion-chip>
            <ion-chip outline color="success" @click="addScoringRecordItem()">
              <ion-icon style="height: 50px; margin: 0px;" :icon="addOutline"></ion-icon>
            </ion-chip>
          </ion-item>
          <ion-item v-for="(sc, i) in scoringRecordItems" :key="i" lines="inset" :color="getColor(sc)">
            <ion-label><h3>{{ i + 1 }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('id')" @click="copyToClipboard(sc.id)"><h3>{{ sc.id }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('date')" @click="copyToClipboard(dayjs(sc.date).format('DD.MM.YYYY'))"><h3>{{ dayjs(sc.date).format('DD.MM.YYYY') }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('courseName')" @click="copyToClipboard(sc.courseName)"><h3>{{ sc.courseName }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('courseId')" @click="copyToClipboard(sc.courseId)"><h3>{{ sc.courseId }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('tournamentName')" @click="copyToClipboard(sc.tournamentName)"><h3>{{ sc.tournamentName }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('round')" @click="copyToClipboard(sc.round)"><h3>{{ sc.round }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('tournamentId')" @click="copyToClipboard(sc.tournamentId)"><h3>{{ sc.tournamentId }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('par')" @click="copyToClipboard(sc.par)"><h3>{{ sc.par }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('courseRating')" @click="copyToClipboard(sc.courseRating)"><h3>{{ sc.courseRating }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('pcc')" @click="copyToClipboard(sc.pcc)"><h3>{{ sc.pcc }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('slopeRating')" @click="copyToClipboard(sc.slopeRating)"><h3>{{ sc.slopeRating }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('holes')" @click="copyToClipboard(sc.holes)"><h3>{{ sc.holes }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('playForm')" @click="copyToClipboard(sc.playForm)"><h3>{{ sc.playForm }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('adjustedGrossScore')" @click="copyToClipboard(sc.adjustedGrossScore)"><h3>{{ sc.adjustedGrossScore }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('snpv')" @click="copyToClipboard(sc.snpv)"><h3>{{ sc.snpv }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('hcpi')" @click="copyToClipboard(sc.hcpi)"><h3>{{ sc.hcpi }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('playingHcpi')" @click="copyToClipboard(sc.playingHcpi)"><h3>{{ sc.playingHcpi }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('scoreDifferential')" @click="copyToClipboard(sc.scoreDifferential)"><h3>{{ sc.scoreDifferential }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('score_differential_pre_pcc_9played')" @click="copyToClipboard(sc.score_differential_pre_pcc_9played)"><h3>{{ sc.score_differential_pre_pcc_9played }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('score_differential_pre_pcc_9notplayed')" @click="copyToClipboard(sc.score_differential_pre_pcc_9notplayed)"><h3>{{ sc.score_differential_pre_pcc_9notplayed }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('score_differential_post_pcc_9played')" @click="copyToClipboard(sc.score_differential_post_pcc_9played)"><h3>{{ sc.score_differential_post_pcc_9played }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('score_differential_post_pcc_9notplayed')" @click="copyToClipboard(sc.score_differential_post_pcc_9notplayed)"><h3>{{ sc.score_differential_post_pcc_9notplayed }}</h3></ion-label>
            <ion-label v-if="scoringRecordPreviewResultData && scoringRecordPreviewResultData.scoringRecordItems.find(i => i.scorecard == sc.id) && enabledColumsChangeHCPI.includes('scoreDifferentialWhsApi')" :color="getScoreDifferentialWhsAPIColor(sc.scoreDifferential, scoringRecordPreviewResultData.scoringRecordItems.find(i => i.scorecard == sc.id).score_differential)"><h3>{{ scoringRecordPreviewResultData.scoringRecordItems.find(i => i.scorecard == sc.id).score_differential }}</h3></ion-label>
            <ion-label v-else-if="enabledColumsChangeHCPI.includes('scoreDifferentialWhsApi')"><h3>-</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('adjustment')" @click="copyToClipboard(sc.adjustment)"><h3>{{ sc.adjustment }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('adjustmentScoreDifferential')" @click="copyToClipboard(sc.adjustmentScoreDifferential)"><h3>{{ sc.adjustmentScoreDifferential }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('scoreDifferentialPostPcc')" @click="copyToClipboard(sc.scoreDifferentialPostPcc)"><h3>{{ sc.scoreDifferentialPostPcc }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('comment')" @click="copyToClipboard(sc.comment)"><h3>{{ sc.comment }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('courseHcpi')" @click="copyToClipboard(sc.courseHcpi)"><h3>{{ sc.courseHcpi }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('exceptionalScore')" @click="copyToClipboard(sc.exceptionalScore)"><h3>{{ sc.exceptionalScore }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('exceptionalScoreDifference')" @click="copyToClipboard(sc.exceptionalScoreDifference)"><h3>{{ sc.exceptionalScoreDifference }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('exceptionalScoreReduction')" @click="copyToClipboard(sc.exceptionalScoreReduction)"><h3>{{ sc.exceptionalScoreReduction }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('ignoreExceptionalScoreAdjustment')" @click="copyToClipboard(sc.ignoreExceptionalScoreAdjustment)"><h3>{{ sc.ignoreExceptionalScoreAdjustment }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('resultStatus')" @click="copyToClipboard(sc.resultStatus)"><h3>{{ sc.resultStatus }}</h3></ion-label>
            <ion-label v-if="enabledColumsChangeHCPI.includes('country')" @click="copyToClipboard(sc.country)"><h3>{{ sc.country }}</h3></ion-label>

            <ion-chip outline color="primary" :disabled="sc.status==1 || sc.status==3" @click="editScoringRecordItem(sc)">
              <ion-icon style="height: 50px; margin: 0px;" :icon="optionsOutline"></ion-icon>
            </ion-chip>
            <ion-chip outline color="danger" @click="deleteScoringRecordItem(sc, i)">
              <ion-icon style="height: 50px; margin: 0px;" :icon="trashOutline"></ion-icon>
            </ion-chip>
          </ion-item>
        </ion-list>
      </section>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonListHeader,
          IonCardHeader, IonCardContent, IonCard, IonIcon, IonChip, IonList, IonItem, alertController,
          modalController, toastController, loadingController, IonReorder, IonReorderGroup, IonToggle, IonCardSubtitle, IonCardTitle, IonCol, IonRow, IonGrid } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useMutation, useResult, useQuery } from '@vue/apollo-composable'
import { trashOutline, optionsOutline, listOutline, addOutline, reorderFourOutline, closeCircle, saveOutline, helpCircleOutline, refreshOutline } from 'ionicons/icons';
import findScoringRecordByPersonIdQueryFile from '../graphql/findScoringRecordByPersonId.query.gql'
import updateScoringRecordItemMutationFile from '../graphql/updateScoringRecordItem.mutation.gql'
import createScoringRecordItemMutationFile from '../graphql/createScoringRecordItem.mutation.gql'
import getScoringRecordPreviewFile from '../graphql/getScoringRecordPreview.query.gql'
import deleteScoringRecordItemMutationFile from '../graphql/deleteScoringRecordItem.mutation.gql'
import calculateScoringRecordMutationFile from '../graphql/calculateScoringRecordMutation.mutation.gql'
import * as dayjs from 'dayjs';
import Storage from "../plugins/storage.js";
import EditScoringRecordItemDialog from './EditScoringRecordItemDialog'
import AddScoringRecordItemDialog from './AddScoringRecordItemDialog'
import SetHcpiBreakDialog from './SetHcpiBreakDialog.vue'
import SetHcpiLockDialog from './SetHcpiLockDialog.vue'
import SetLowHcpiDialog from './SetLowHcpiDialog.vue'
import SetSDAdjustmentDialog from './SetSDAdjustmentDialog.vue'
import ResetHcpiDialog from './ResetHcpiDialog.vue'
import lodash from 'lodash'
import { Plugins } from '@capacitor/core';
const { Clipboard, Toast } = Plugins;

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton, IonLabel, IonCardHeader, IonCardContent, IonCard,IonIcon, IonChip, IonList, IonItem,
    IonListHeader, IonToggle, IonCardSubtitle, IonCardTitle, IonCol, IonRow, IonGrid,
    alertController,
    loadingController, 
  },
  setup() {
    const { refetch: findScoringRecordByPersonIdRefetch, result: findScoringRecordByPersonIdResult } = useQuery(findScoringRecordByPersonIdQueryFile, {personId: ''})
    const { refetch: getScoringRecordPreviewRefetch, result: getScoringRecordPreviewResult } = useQuery(getScoringRecordPreviewFile, () => ({ scoringRecordItems: [], lowHcpi: undefined, hcpiBreak: undefined }) , () => ({ fetchPolicy: 'no-cache' } ))
    const { mutate: deleteScoringRecordItemMutation } = useMutation(deleteScoringRecordItemMutationFile)
    const scoringRecordPreviewResultData = useResult(getScoringRecordPreviewResult, null, data => data.getScoringRecordPreview)
    const { mutate: updateScoringRecordItemMutation } = useMutation(updateScoringRecordItemMutationFile);
    const { mutate: createScoringRecordItemMutation } = useMutation(createScoringRecordItemMutationFile);
    const { mutate: calculateScoringRecordMutation } = useMutation(calculateScoringRecordMutationFile)

    const asdf = useResult(findScoringRecordByPersonIdResult, null, data => data.findScoringRecordByPersonId.scoringRecordItems)
    let scoringRecordItems = asdf
    const scoringRecord = useResult(findScoringRecordByPersonIdResult, null, data => data.findScoringRecordByPersonId)
    //scoringRecordItems = JSON.parse(JSON.stringify(scoringRecordItems))
    
    let originalScoringRecordItems = [];
    let deletedScoringRecordItems = [];


    return {
      Clipboard, Toast, scoringRecord, scoringRecordItems, findScoringRecordByPersonIdRefetch, closeCircle, saveOutline, helpCircleOutline, refreshOutline,
      addOutline, reorderFourOutline, trashOutline, listOutline, optionsOutline, dayjs, scoringRecordPreviewResultData, deleteScoringRecordItemMutation, createScoringRecordItemMutation, calculateScoringRecordMutation,
      originalScoringRecordItems, deletedScoringRecordItems, updateScoringRecordItemMutation, getScoringRecordPreviewRefetch, getScoringRecordPreviewResult
    }
  },
  watch: {
    '$store.getters.selectedPerson' (val) {
      this.findScoringRecordByPersonIdRefetch({ personId: val.id}).then(res => {
        this.getScoringRecordPreview()
      })
      
    },
  },
  computed: {
    hcpiBreakColor: function() {
      if (this.scoringRecord && this.scoringRecord.hcpiBreak == true) return 'success'
      return 'danger'
    },
    hcpiLockColor: function() {
      if (this.scoringRecord && this.scoringRecord.hcpiLock == true) return 'success'
      return 'danger'
    },
    proStatusColor: function() {
      if (this.scoringRecord && this.scoringRecord.proWithHcpi == true) return 'success'
      return 'danger'
    },
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
      selectedPerson : function(){
        return this.$store.getters.selectedPerson
      },
      columsChangeHCPI : function(){
        return this.$store.getters.columsChangeHCPI
      },
      enabledColumsChangeHCPI : function(){
        let enabledCols = ['']
        for (const item of this.$store.getters.columsChangeHCPI) {
          if (item.selected)
              enabledCols.push(item.name)
        }
        return enabledCols
      },
  },
  methods: {
    refreshPage() {
      this.$router.go()
    },
    async openResetHcpiDialog() {
      const modal = await modalController
        .create({
          component: ResetHcpiDialog,
          componentProps: {
            propsData: {
              scoringRecord: this.scoringRecord,
            }
          },
        })

      await modal.present()

      let modalResponse = await modal.onDidDismiss();
      const modalResponseData = JSON.stringify(modalResponse.data);

      this.findScoringRecordByPersonIdRefetch()

    },
    async openSetSDAdjustmentDialog() {
      const modal = await modalController
        .create({
          component: SetSDAdjustmentDialog,
          componentProps: {
            propsData: {
              scoringRecord: this.scoringRecord,
            }
          },
        })

      await modal.present()

      let modalResponse = await modal.onDidDismiss();
      const modalResponseData = JSON.stringify(modalResponse.data);

      this.findScoringRecordByPersonIdRefetch()

    },
    async openSetLowHcpiDialog() {
      const modal = await modalController
        .create({
          component: SetLowHcpiDialog,
          componentProps: {
            propsData: {
              scoringRecord: this.scoringRecord,
            }
          },
        })

      await modal.present()

      let modalResponse = await modal.onDidDismiss();
      const modalResponseData = JSON.stringify(modalResponse.data);

      this.findScoringRecordByPersonIdRefetch()

    },
    async openSetHcpiBreakDialog() {
      const modal = await modalController
        .create({
          component: SetHcpiBreakDialog,
          componentProps: {
            propsData: {
              scoringRecord: this.scoringRecord,
            }
          },
        })

      await modal.present()

      let modalResponse = await modal.onDidDismiss();
      const modalResponseData = JSON.stringify(modalResponse.data);

      this.findScoringRecordByPersonIdRefetch()

    },
    async openSetHcpiLockDialog() {
      const modal = await modalController
        .create({
          component: SetHcpiLockDialog,
          componentProps: {
            propsData: {
              scoringRecord: this.scoringRecord,
            }
          },
        })

      await modal.present()

      let modalResponse = await modal.onDidDismiss();
      const modalResponseData = JSON.stringify(modalResponse.data);

      this.findScoringRecordByPersonIdRefetch()
    },
    getScoreDifferentialWhsAPIColor(scoreDifferential, scoreDifferentialWhsAPI) {
      if (scoreDifferential != scoreDifferentialWhsAPI) return 'warning'
    },

    async getScoringRecord() {
      const alert = await alertController
        .create({
          header: 'WHS Server rechnen',
          message: '<strong>Den Scoring Record wirklich neu berechnen am WHS Server?</strong>?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Ok',
              handler: async () => {
                   const loading = await loadingController.create({
                      message: 'Daten werden am WHS Server berechnet...',
                    });
                    await loading.present();

                    const dgvSpielerId = this.selectedPerson.id
                

                    return this.calculateScoringRecordMutation({ dgvPlayerId: dgvSpielerId }).then(res => {
                      loading.dismiss()
                      this.findScoringRecordByPersonIdRefetch()
                      return 
                    })
                    .catch(async err => {
                      loading.dismiss()
                      const alert = await alertController.create({
                        header: 'WHS API Fehler',
                        message: JSON.stringify(err),
                        buttons: [
                          {
                            text: 'Ok',
                          },
                          {
                            text: 'Copy Error to Clipboard',
                            handler: () => {
                              this.copyToClipboard(JSON.stringify(err))
                            },
                          },
                        ],
                    });

                    await alert.present();
                    })

              },
            },
          ],
        });
      return alert.present();
    },

    async getScoringRecordPreview(additionalRecord) {
      const loading = await loadingController.create({
        message: 'Daten werden am WHS API berechnet...',
      });
      await loading.present();

      const scoringRecord = this.scoringRecord
  
      let apiCall = {
        scoringRecordItems: [],
        lowHcpi: scoringRecord ? scoringRecord.lowHcpi : undefined,
        hcpiBreak: scoringRecord ? scoringRecord.hcpiBreak : undefined
        }
//console.log(new Date('23-11-2020'))
      if (scoringRecord && scoringRecord.scoringRecordItems.length > 0) {
        scoringRecord.scoringRecordItems.forEach(scoringRecordItem => {
          if (scoringRecordItem.par == null) return
          let resultType = 'gross'
          let result = scoringRecordItem.adjustedGrossScore

          if (scoringRecordItem.tournamentName == 'HCPI Ankereintrag der EGA-HCP Umrechnung' || scoringRecordItem.tournamentName == 'HCPI Ankereintrag der EGA-HCP Umrechnung aufgrund PE/PR') {
            resultType = 'anchor'
          }
          else if (scoringRecordItem.snpv > 0 && new Date(scoringRecordItem.date) < new Date('2020-11-23')) {
            resultType = 'stableford'
            result = scoringRecordItem.snpv
          }

          apiCall.scoringRecordItems.push({
            scorecard: String(scoringRecordItem.id),
            date: String(scoringRecordItem.date),
            course: String(scoringRecordItem.courseId),
            par: parseInt(scoringRecordItem.par),
            cr: parseFloat(scoringRecordItem.courseRating),
            slope: parseInt(scoringRecordItem.slopeRating),
            pcc: scoringRecordItem.pcc != null ? parseInt(scoringRecordItem.pcc) : 0,
            result: parseFloat(result),
            score_differential_adjustment: parseInt(scoringRecordItem.adjustment),
            result_type: resultType,
            hcpi: parseFloat(scoringRecordItem.hcpi),
            ignore_exceptional_score_adjustment: scoringRecordItem.ignoreExceptionalScoreAdjustment === 'true',
            //hcpi_relevant is always true because only hcpi relevant scores enter the scoring Record
            hcpi_relevant: true,
            nine_hole: scoringRecordItem.holes === 9,
            nine_hole_calc_reverse: scoringRecordItem.nine_hole_calc_reverse
          })
      })
      }


      if (additionalRecord) apiCall.scoringRecordItems.push(additionalRecord)

      apiCall.scoringRecordItems.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
      });

      apiCall.scoringRecordItems = apiCall.scoringRecordItems.slice(0, 20)
       
      return this.getScoringRecordPreviewRefetch(apiCall).then(res => {
        loading.dismiss()
        return this.getScoringRecordPreviewResult
      })
      .catch(async err => {
        loading.dismiss()
        const alert = await alertController.create({
          header: 'WHS API Fehler',
          message: JSON.stringify(err),
          buttons: [
            {
              text: 'Ok',
            },
            {
              text: 'Copy Error to Clipboard',
              handler: () => {
                this.copyToClipboard(JSON.stringify(err))
              },
            },
          ],
      });

      await alert.present();
      })
    },
    copyToClipboard(text) {
      Clipboard.write({string: text })
      Toast.show({
          text: `Copied ${text} to Clipboard`
        })
    },
    openSelectedColumnsDropdown() {
      this.$refs.selectedColumnsDropdown.open()
    },
    getColor(sr) {
      if (sr.status && sr.status == 1) return "dangerlight"
      else if (sr.status && sr.status == 2) return "warninglight"
      else if (sr.status && sr.status == 3) return "successlight"
      return null
    },
    async setEnabledColumsChangeHCPI(enabledColumns) {
      const columsChangeHCPI = this.$store.getters.columsChangeHCPI
      columsChangeHCPI.forEach(column => {
        if (enabledColumns.includes(column.name)) {
          column.selected = true
        } else {
          column.selected = false
        }
      })
      this.$store.dispatch('changecolumsChangeHCPI', columsChangeHCPI)
      //await Storage.setItem('language', language);
    },
    async deleteScoringRecordItem(scoringRecordItem, index) {
      // If the status is 1 (deleted) then just set the status to 0 to remove the deleted state
      if (scoringRecordItem.status && scoringRecordItem.status == 1) {
        return scoringRecordItem.status=0
      }

      if (scoringRecordItem.status && scoringRecordItem.status == 3) {
        return this.scoringRecordItems.splice(index, 1)
      }
      else if (scoringRecordItem.status && scoringRecordItem.status == 1)
        return scoringRecordItem.status = 0
      else
        return scoringRecordItem.status=1

      // Otherwise show an alert and set the item to delted
      /*
      const alert = await alertController
        .create({
          header: this.$t('deleteScoringRecordItemHeader') + ` ${scoringRecordItem.id}`,
          message: this.$t('deleteScoringRecordItemMessage') + `<strong> ${scoringRecordItem.id} </strong>`,
          buttons: [
            {
              text: this.$t('cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: this.$t('ok'),
              handler: () => {
                if (scoringRecordItem.status && scoringRecordItem.status == 3) {
                  return this.scoringRecordItems.splice(index, 1)
                }
                else if (scoringRecordItem.status && scoringRecordItem.status == 1)
                  return scoringRecordItem.status = 0
                else
                  return scoringRecordItem.status=1

              },
            },
          ],
        });
      return alert.present();
      */
    },
    async editScoringRecordItem(scoringRecordItem) {
      // First save the original version
      if (!this.originalScoringRecordItems.find(element => element.id == scoringRecordItem.id)) {
        this.originalScoringRecordItems.push(JSON.parse(JSON.stringify(scoringRecordItem)))
      }

      const scoringRecordItemOriginal = JSON.stringify(this.originalScoringRecordItems.find(element => element.id == scoringRecordItem.id))

      if (!this.scoringRecordPreviewResultData) await this.getScoringRecordPreview()

      const modal = await modalController
        .create({
          component: EditScoringRecordItemDialog,
          componentProps: {
            propsData: {
              scoringRecordItem,
              scoringRecordPreviewResultData: this.scoringRecordPreviewResultData.scoringRecordItems.find(item => item.scorecard == scoringRecordItem.id)

            }
          },
        })

      await modal.present()

      let modalResponse = await modal.onDidDismiss();
      const modalResponseData = JSON.stringify(modalResponse.data);

      // If it has been edited
      if (modalResponseData == 'true') {
        // Reset the data
        for (let [key, value] of Object.entries(scoringRecordItem)) {
          scoringRecordItem[key] = JSON.parse(scoringRecordItemOriginal)[key]
        }

        scoringRecordItem.status = 0
      } else if (modalResponseData && scoringRecordItemOriginal != modalResponseData) {
        scoringRecordItem.status = 2
      }
    },
    async addScoringRecordItem() {
      const modal = await modalController
        .create({
          component: AddScoringRecordItemDialog,
          componentProps: {
            propsData: {
              getScoringRecordPreview: this.getScoringRecordPreview,
              scoringRecordPreviewResultData: this.scoringRecordPreviewResultData.scoringRecordItems.find(item => item.scorecard == "preview")
            }
          }
        })

      await modal.present()

      let modalResponse = await modal.onDidDismiss();
      const modalResponseData = modalResponse.data;
      if (!modalResponseData) return

      //console.log('adsafdadfadfasd')
      //console.log(JSON.stringify(modalResponse.tournamentType))

      if (this.scoringRecordItems.length < 1) {
        this.scoringRecordItems = []
      }

      this.scoringRecordItems.unshift({
        status: 3,
        adjustedGrossScore: modalResponseData.adjustedGrossScore,
        adjustment: 0,
        comment: modalResponseData.comment,
        courseHcpi: null,
        courseId: null,
        courseName: null,
        courseRating: modalResponseData.courseRating,
        country: modalResponseData.country,
        date: modalResponseData.date,
        exceptionalScore: null,
        exceptionalScoreDifference: null,
        exceptionalScoreReduction: null,
        hcpi: modalResponseData.hcpi,
        playingHcpi: modalResponseData.playingHcpi,
        holes: modalResponseData.holes,
        id: "999999999",
        ignoreExceptionalScoreAdjustment: modalResponseData.ignoreExceptionalScoreAdjustment,
        par: modalResponseData.par,
        pcc: modalResponseData.pcc,
        playForm: modalResponseData.playForm,
        tournamentType: modalResponseData.tournamentType,
        resultStatus: modalResponseData.resultStatus,
        round: modalResponseData.round,
        scoreDifferential: null,
        score_differential_pre_pcc_9played: null,
        score_differential_pre_pcc_9notplayed: null,
        score_differential_post_pcc_9played: null,
        score_differential_post_pcc_9notplayed: null,
        scoreDifferentialPostPcc: null,
        slopeRating: modalResponseData.slopeRating,
        snpv: modalResponseData.snpv,
        tournamentId: "0",
        tournamentClub: modalResponseData.tournamentClub,
        tournamentName: modalResponseData.tournamentName,
        })
    },
    async saveScoringRecord() {
      const addedScoringRecordItems = this.scoringRecordItems.filter(item => item.status == 3);
      const editedScoringRecordItems = this.scoringRecordItems.filter(item => item.status == 2);
      const deletedScoringRecordItems = this.scoringRecordItems.filter(item => item.status == 1);

      const alert = await alertController
        .create({
          header: 'Alert',
          subHeader: 'Subtitle',
          message: `
          Hizugefügt:
          <ul>${addedScoringRecordItems.map(item => `<li>${item.id}</li>`).join(" ")}</ul>

          Bearbeitet:
          <ul>${editedScoringRecordItems.map(item => `<li>${item.id}</li>`).join(" ")}</ul>

          Gelöscht:
          <ul>${deletedScoringRecordItems.map(item => `<li>${item.id}</li>`).join(" ")}</ul>
          `,
          buttons: [{
            text: 'Ok',
            handler: async () => {
              const loading = await loadingController.create({
                message: 'Please wait...',
              });
              await loading.present();

              const promises = []
              editedScoringRecordItems.forEach(scoringRecordItem => {
                promises.push(this.updateScoringRecordItemMutation({
                  "dgvPlayerId": this.selectedPerson.id,
                  "scoringRecordItemId": scoringRecordItem.id,
                  "date": scoringRecordItem.date,
                  "tournamentName": scoringRecordItem.tournamentName,
                  "tournamentType": parseInt(scoringRecordItem.tournamentType),
                  "round": parseInt(scoringRecordItem.round) || undefined,
                  "tournamentClub": parseInt(scoringRecordItem.tournamentClub) || undefined,
                  "par": parseInt(scoringRecordItem.par),
                  "courseRating": parseFloat(scoringRecordItem.courseRating),
                  "slopeRating": parseInt(scoringRecordItem.slopeRating),
                  "playForm": scoringRecordItem.playForm,
                  "holes": parseInt(scoringRecordItem.holes),
                  "adjustedGrossScore": parseInt(scoringRecordItem.adjustedGrossScore),
                  "snpv": parseInt(scoringRecordItem.snpv),
                  "hcpi": parseFloat(scoringRecordItem.hcpi) || undefined,
                  "playingHcpi": parseInt(scoringRecordItem.playingHcpi),
                  "pcc": parseInt(scoringRecordItem.pcc),
                  "adjustment": parseInt(scoringRecordItem.adjustment),
                  "comment": scoringRecordItem.comment,
                  "ignoreExceptionalScoreAdjustment": scoringRecordItem.ignoreExceptionalScoreAdjustment === 'true',
                  "resultStatus": scoringRecordItem.resultStatus,
                  "country": parseInt(scoringRecordItem.country),
                }))
              })

              deletedScoringRecordItems.forEach(scoringRecordItem => {
                promises.push(this.deleteScoringRecordItemMutation({
                  dgvPlayerId: this.selectedPerson.id,
                  scoringRecordItemId: scoringRecordItem.id,
                }))
              })

              addedScoringRecordItems.forEach(item => {
                this.createScoringRecordItemMutation({
                    dgvPlayerId: String(this.selectedPerson.id),
                    date: String(new Date(item.date).toISOString()),
                    tournamentName: String(item.tournamentName),
                    round: parseInt(item.round),
                    tournamentClub: parseInt(item.tournamentClub),
                    par: parseInt(item.par),
                    courseRating: parseFloat(item.courseRating),
                    slopeRating: parseFloat(item.slopeRating),
                    holes: parseInt(item.holes),
                    playForm: String(item.playForm),
                    tournamentType: parseInt(item.tournamentType),
                    adjustedGrossScore: item.adjustedGrossScore != 0 ? parseFloat(item.adjustedGrossScore) : undefined,
                    snpv: item.snpv != 0 ? parseInt(item.snpv) : undefined,
                    hcpi: parseFloat(item.hcpi),
                    playingHcpi: parseFloat(item.playingHcpi),
                    pcc: parseFloat(item.pcc),
                    comment: item.comment,
                    ignoreExceptionalScoreAdjustment: item.ignoreExceptionalScoreAdjustment === 'true',
                    resultStatus: String(item.resultStatus),
                    country: parseInt(item.country)
                })

              })

              await Promise.all(promises)
              await loading.dismiss()

              setTimeout(() => { this.$router.go() }, 400)
              //await this.findScoringRecordByPersonIdRefetch({ personId: this.selectedPerson.id });
          }}],
        });
      await alert.present();
    }
  },
  mounted() {
    if (this.selectedPerson) {
      this.findScoringRecordByPersonIdRefetch({ personId: this.selectedPerson.id }).then(res => {
        this.getScoringRecordPreview()
      })
    }
  },
});

</script>

<style scoped>

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

ion-card {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  max-width : 100% !important;
  margin: 0 !important;
}



</style>