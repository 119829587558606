<template>
  <ion-app>
  <ion-split-pane content-id="main">
    <!--  the side menu  -->
    <ion-menu side="start" content-id="main">
      <ion-header>
      </ion-header>
      <ion-content>

      <ion-list id="start-list">
        <ion-list-header class="ion-margin-bottom"><ion-img style="width: 30px; height:30px" :src="logo" />&nbsp;{{ $t('dgvwhsadmin') }}
        </ion-list-header>

        <ion-item v-if="UserData">
              <ion-label style="margin-left: 5px">
                <h2>{{ UserData.lastName }} {{ UserData.firstName }}</h2>
              </ion-label>
        <ion-item lines="none">
          <ion-label>Test Mode</ion-label>
          <ion-toggle :checked="environment" @ionChange="setEnvironment($event.target.checked)" slot="start" name="environment"></ion-toggle>
        </ion-item>
        </ion-item>

        <ion-item lines="none" v-if="isLoggedIn">

          <ion-icon @click="openSearchIndicatorAlert('birthday', findPersonResult.findPerson.birthday.value)" color="success" v-if="findPersonResult && findPersonResult.findPerson.birthday.found" :icon="calendar"></ion-icon>
          <ion-icon @click="openSearchIndicatorAlert('birthday')" v-else :icon="calendarOutline"></ion-icon>

          <ion-icon @click="openSearchIndicatorAlert('dgvPlayerId', findPersonResult.findPerson.dgvPlayerId.value)" color="success" v-if="findPersonResult && findPersonResult.findPerson.dgvPlayerId.found" :icon="ellipse"></ion-icon>
          <ion-icon @click="openSearchIndicatorAlert('dgvPlayerId')" v-else :icon="ellipseOutline"></ion-icon>
        
          <ion-icon @click="openSearchIndicatorAlert('homeClubNumber', findPersonResult.findPerson.homeClubNumber.value)" color="success" v-if="findPersonResult && findPersonResult.findPerson.homeClubNumber.found" :icon="home"></ion-icon>
          <ion-icon @click="openSearchIndicatorAlert('homeClubNumber')" v-else :icon="homeOutline"></ion-icon>

          <ion-icon @click="openSearchIndicatorAlert('lastName', findPersonResult.findPerson.lastName.value)" color="success" v-if="findPersonResult && findPersonResult.findPerson.lastName.found" :icon="chevronBackCircle"></ion-icon>
          <ion-icon @click="openSearchIndicatorAlert('lastName')" v-else :icon="chevronBackCircleOutline"></ion-icon>

          <ion-icon @click="openSearchIndicatorAlert('fistName', findPersonResult.findPerson.firstName.value)" color="success" v-if="findPersonResult && findPersonResult.findPerson.firstName.found" :icon="chevronForwardCircle"></ion-icon>
          <ion-icon @click="openSearchIndicatorAlert('fistName')" v-else :icon="chevronForwardCircleOutline"></ion-icon>

          </ion-item>
          
          <ion-searchbar v-if="isLoggedIn" :placeholder="$t('searchplayer')" @ionBlur="findRecents(false)" @ionFocus="findRecents(true)" @ionClear="findPerson('')" v-model="search"></ion-searchbar>

          <ion-progress-bar v-if="findPersonLoading" type="indeterminate"></ion-progress-bar>
          <ion-list v-if="personsFound && personsFound.length > 0 && !findPersonLoading">
            <ion-list-header>
              {{ $t('searchresult') }}
            </ion-list-header>
            <ion-item v-for="(person, i) in personsFound" :key="i" @click="addPerson(person, findPersonLoading)">
                <ion-label style="margin-left: 5px">
                  <h2>{{ person.lastName }} {{ person.firstName }}</h2>
                  <p>{{ person.id }} / {{ person.homeClubNumber }} / {{ dayjs(person.birthday).format('DD.MM.YYYY') }}</p>
                </ion-label>
            </ion-item>
          </ion-list>

          <ion-list v-if="recentsFound && recentsFound.length > 0">
          <ion-list-header>
            {{ $t('history') }}
          </ion-list-header>
          <ion-item v-for="(recent, i) in recentsFound" :key="i">
                <ion-label style="margin-left: 5px" @click="selectPerson(recent)">
                  <h2>{{ recent.lastName }} {{ recent.firstName }}</h2>
                  <p>{{ recent.id }}</p>
                </ion-label>
                <ion-icon v-if="!isLoggedIn" @click="deleteRecent(recent)" slot="end" :ios="trashOutline" :md="trashOutline"></ion-icon>
            </ion-item>
          </ion-list>

          <ion-menu-toggle auto-hide="false">
          <ion-item v-if="isLoggedIn && selectedPerson && (recentsFound == null || (recentsFound != null && recentsFound.length <= 0))
              && (personsFound == null || (personsFound != null && personsFound.length <= 0))">
                <ion-label style="margin-left: 5px">
                  <h2>{{ selectedPerson.lastName }} {{ selectedPerson.firstName }}</h2>
                  <p>{{ selectedPerson.id }} / {{ selectedPerson.homeClubNumber }} / {{ dayjs(selectedPerson.birthday).format('DD.MM.YYYY') }}</p>
                </ion-label>
                <ion-icon @click="removePerson()" color="danger" slot="end" :ios="closeCircleOutline" :md="closeCircleOutline"></ion-icon>
          </ion-item>
          </ion-menu-toggle>

        <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
          <ion-item v-if="p.showNav && (p.requiresLogin == false || isLoggedIn)" @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
            <ion-icon slot="start" :ios="p.icon" :md="p.icon"></ion-icon>
            <ion-label>{{ $t(p.title) }}</ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-item v-if="isLoggedIn" lines="none" @click="logout()">
          <ion-icon slot="start" :ios="logOutOutline" :md="logOutOutline"></ion-icon>
          <ion-label>{{ $t('logout') }}</ion-label>
        </ion-item>

        <ion-item v-if="!isLoggedIn" lines="none" @click="login()">
          <ion-icon slot="start" :ios="logInOutline" :md="logInOutline"></ion-icon>
          <ion-label>{{ $t('login') }}</ion-label>
        </ion-item>

      </ion-list>
          

      </ion-content>
    </ion-menu>

    <!-- the main content -->
     <div class="ion-page" id="main">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-title>{{ $t(appPages[selectedIndex].title) }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-router-outlet></ion-router-outlet>
    </div>
  </ion-split-pane>
  </ion-app>
</template>

<script>
import { toastController, modalController, IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, 
IonButtons, IonGrid, IonImg, IonCol, IonRow, IonItemDivider, IonSearchbar, IonApp, IonContent, IonIcon, IonItem, 
IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane, IonSelect, 
IonSelectOption, IonFabList, IonFabButton, IonFab, IonBadge, alertController, IonProgressBar, IonCardSubtitle, IonCardTitle, IonToggle } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { arrowForwardCircleOutline, removeOutline, logOutOutline, logInOutline, personOutline, settingsOutline, peopleOutline,  
calendarSharp, calendarOutline, archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, 
mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, homeOutline, homeSharp, warningOutline, warningSharp, personCircleOutline,
businessOutline, eyeOutline, caretUp, addOutline, personAddOutline, createOutline, fastFoodOutline, globeOutline, cartOutline, constructOutline,
closeCircleOutline, ellipseOutline, chevronBackCircleOutline, chevronForwardCircleOutline, calendar, ellipse, home, chevronBackCircle, chevronForwardCircle,
 } from 'ionicons/icons';
import findPersonQueryFile from './graphql/findPerson.query.gql'
import { useQuery, useResult, useMutation} from '@vue/apollo-composable'
import LoginDialog from './views/Login'
import logoutUserMutationFile from './graphql/logoutUser.mutation.gql'
import Storage from "./plugins/storage.js";
import * as dayjs from 'dayjs';

import {
  Plugins,
} from '@capacitor/core';

const { PushNotifications } = Plugins;

export default defineComponent({
  name: 'App',
  components: {
    IonApp, IonImg,
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
    IonSearchbar,
    IonItemDivider,
    IonGrid, 
    IonCol, 
    IonRow,
    IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, IonButtons, IonSelect, IonSelectOption,
    IonFabList, IonFabButton, IonFab, IonBadge, IonProgressBar, IonCardSubtitle, IonCardTitle, IonToggle
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'home',
        url: '/home',
        icon: homeOutline,
        requiresLogin: true,
        showNav: true
      },
      {
        title: 'hcpichange',
        url: '/hcpichange',
        icon: constructOutline,
        requiresLogin: true,
        showNav: true
      },
      {
      title: 'profile',
      url: '/profile',
      icon: personOutline,
      requiresLogin: true,
      showNav: true
      },
      {
      title: 'users',
      url: '/users',
      icon: personCircleOutline,
      requiresLogin: true,
      showNav: true
      },
      {
      title: 'resetPassword',
      url: '/resetPassword',
      icon: personCircleOutline,
      requiresLogin: false,
      showNav: false
      }
    ];

    const path = window.location.pathname.split('/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    
    const route = useRoute();

    const { refetch: findPersonRefetch, result: findPersonResult, query: findPersonQuery, loading: findPersonLoading } = useQuery(findPersonQueryFile, null)

    const personsFound = useResult(findPersonResult, null, data => data.findPerson.persons)

    const { mutate: logoutUserMutation } = useMutation(logoutUserMutationFile, { fetchPolicy: 'no-cache' });

    return { 
      selectedIndex,
      appPages, 
      archiveOutline, 
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      homeSharp,
      homeOutline,
      calendarSharp,
      dayjs,
      calendarOutline, logInOutline, logOutOutline, arrowForwardCircleOutline, removeOutline, eyeOutline, caretUp, addOutline,businessOutline,
      peopleOutline, personAddOutline,settingsOutline,closeCircleOutline,
      findPersonRefetch, findPersonResult, findPersonQuery, findPersonLoading,
      personsFound, logoutUserMutation, createOutline, fastFoodOutline, globeOutline, cartOutline, constructOutline, personCircleOutline,
      ellipseOutline, chevronBackCircleOutline, chevronForwardCircleOutline, calendar, ellipse, home, chevronBackCircle, chevronForwardCircle,
    }
  },
  computed: {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
      UserData : function(){ return this.$store.getters.UserData},
      selectedPerson : function(){ return this.$store.getters.selectedPerson},
      persons : function(){ return JSON.parse(this.$store.getters.persons)},
    },
  methods: {
    async setEnvironment(environment) {
      await Storage.setItem('environment', environment);
      setTimeout(() => { this.$router.go() }, 400)
    },
    async openSearchIndicatorAlert(description, value) {
      if (!value) {
        const alert = await alertController.create({
          header: `Kein Wert für ${description} in der aktuellen Suche gefunden`,
          buttons: ['Ok']
        });

        return await alert.present();
      }

      const alert = await alertController.create({
        header: description,
        message: `${value}`,
        buttons: ['Ok']
      });

      return await alert.present();
    },
    async deleteRecent(recent) {
      const persons = this.$store.getters.persons != null ? JSON.parse(this.$store.getters.persons) : []
      persons.splice(persons.findIndex(o => o.id == recent.id), 1)
      await Storage.setItem('persons', JSON.stringify(persons))
      this.$store.dispatch('getPersons')
    },
    async selectPerson(person) {
        const persons = this.$store.getters.persons != null ? JSON.parse(this.$store.getters.persons) : []
        if (persons.findIndex(o => o.id == person.id) != 0) {
          persons.splice(persons.findIndex(o => o.id == person.id), 1)
          persons.unshift(person)
          await Storage.setItem('persons', JSON.stringify(persons))
          this.$store.dispatch('getPersons')
        }

        await Storage.setItem('selectedPerson', JSON.stringify(person))
        this.$store.dispatch('getselectedPerson')
        this.recentsFound = []
    },
    async removePerson() {
      this.$store.dispatch('removeselectedPerson')
    },
    findRecents(value) {
      if (value && this.searchBarValue.length > 0) {
        this.recentsFound = []
        return this.findPerson(this.searchBarValue)
      }

      //this.findPersonResult = undefined
      if (value)
        this.recentsFound = this.persons
      else
        setTimeout(() => { this.recentsFound = [] }, 200)
      
    },
    async addPerson(person, loading) {
      if (loading) {
        return toastController
          .create({
            message: 'Please wait',
            duration: 2000,
            color: 'success'
          }).then(res => res.present())
      }
      const persons = this.$store.getters.persons != null ? JSON.parse(this.$store.getters.persons) : []
      const found = persons.filter(e => e.id === person.id).length > 0
      if (!found) {
        persons.unshift(person)
        await Storage.setItem('persons', JSON.stringify(persons))
        this.$store.dispatch('getPersons')
      }
      else if (persons.findIndex(o => o.id == person.id) != 0) {
        persons.splice(persons.findIndex(o => o.id == person.id))
        persons.unshift(person)
        await Storage.setItem('persons', JSON.stringify(persons))
        this.$store.dispatch('getPersons')
      }
      await Storage.setItem('selectedPerson', JSON.stringify(person))
      this.$store.dispatch('getselectedPerson')
      this.findPersonRefetch({ searchTerm: ''})
    },
    async logout() {
        const refreshToken = await Storage.getItem('refreshToken')
        this.logoutUserMutation({
          refreshToken: refreshToken
        })
        .then(res => {
          this.$store.dispatch('logout')

          toastController
            .create({
              message: 'Logout Successful',
              duration: 2000,
              color: 'success'
            }).then(res => res.present())
            this.$router.push('home')
        })
        .catch(err => {
          toastController
            .create({
              message: 'Logout not Successful',
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        })
    },
    async login() {
      const modal = await modalController
        .create({
          component: LoginDialog,
        })
      return modal.present();
    },
    async findPerson(searchTerm) {
      if (searchTerm.length > 0)
        this.recentsFound = []
      if (searchTerm.length > 0) {
          this.findPersonRefetch({ searchTerm: searchTerm})
      }
      else {
        this.findRecents(true)
        //this.findPersonRefetch({ searchTerm: ''})
        //this.enable({ searchTerm })
      }
    },
  },
  watch: {
    search: function (val) {
      this.searchBarValue = val;
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.findPerson(val)
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
},
  async mounted() {
    this.$store.dispatch('getselectedPerson')
  },
  data() {
    return {
      environment: this.$store.getters.environment,
      recentsFound: [],
      logo: require('@/assets/images/logo/dgvlogo.png'),
      search: "",
      awaitingSearch: false,
      searchBarValue: ''
    }
  }
});
</script>


<style scoped>

ion-menu-toggle.submenu {
    overflow: hidden;
    display: block;
    height: 10;
    transition: height .3s linear;
    padding-left: 20%;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#start-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#start-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#favorites-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-list#search-result-list {
  padding-top: 0px;

  font-size: 16px;

  color: #757575;

  min-height: 26px;

  margin-bottom: 8px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#favorites-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}


</style>

<style lang="scss">

.ion-color-successlight {
  --ion-color-base: var(--ion-color-successlight);
  --ion-color-base-rgb: var(--ion-color-successlight-rgb);
  --ion-color-contrast: var(--ion-color-successlight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-successlight-contrast-rgb);
  --ion-color-shade: var(--ion-color-successlight-shade);
  --ion-color-tint: var(--ion-color-successlight-tint);
}

.ion-color-warninglight {
  --ion-color-base: var(--ion-color-warninglight);
  --ion-color-base-rgb: var(--ion-color-warninglight-rgb);
  --ion-color-contrast: var(--ion-color-warninglight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warninglight-contrast-rgb);
  --ion-color-shade: var(--ion-color-warninglight-shade);
  --ion-color-tint: var(--ion-color-warninglight-tint);
}

.ion-color-dangerlight {
  --ion-color-base: var(--ion-color-dangerlight);
  --ion-color-base-rgb: var(--ion-color-dangerlight-rgb);
  --ion-color-contrast: var(--ion-color-dangerlight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dangerlight-contrast-rgb);
  --ion-color-shade: var(--ion-color-dangerlight-shade);
  --ion-color-tint: var(--ion-color-dangerlight-tint);
}

ion-app.platform-ios12 ion-content { pointer-events: auto; }
ion-app.platform-ios14 ion-content { pointer-events: auto;  }

.ios ion-content {
   pointer-events: auto;
}

ion-fab-button[data-desc] {
  position: relative;
}

ion-fab-button[data-desc]::after {
  position: absolute;
  content: attr(data-desc);
  z-index: 1;
  right: 45px;
  bottom: 3px;
  background-color: lightgray;
  padding: 8px;
  border-radius: 15px;
  color: black;
  box-shadow: 0 3px 5px -1px grey;
}

.register-modal .modal-wrapper {
    height: 75%;
 }

.booking-modal .modal-wrapper {
    height: 40%;
 }

</style>