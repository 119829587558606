<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title>Registrieren</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right">Test</ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
        <ion-label position="stacked">Firstname</ion-label>
        <ion-input placeholder="Max" type="text" v-model="firstName"></ion-input>
      </ion-item>
        <ion-item>
        <ion-label position="stacked">Lastname</ion-label>
        <ion-input placeholder="Mustermann" type="text" v-model="lastName"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Email</ion-label>
        <ion-input placeholder="test@test.com" type="email" v-model="email"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Password</ion-label>
        <ion-input placeholder="" type="password" v-model="password"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Birthday</ion-label>
        <ion-datetime value="1990-02-20" placeholder="Select Date" v-model="birthdate"></ion-datetime>
      </ion-item>
      <ion-button @click="registerUser()" expand="block" color="success">Register</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonDatetime, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, closeCircleOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'
import registerUserMutationFile from '../graphql/registerUser.mutation.gql'

import * as dayjs from 'dayjs';

export default defineComponent({
  name: 'RegisterDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonDatetime, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      birthdate: null
    }
  },
  setup() {
    const { mutate: registerUserMutation } = useMutation(registerUserMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline,
      closeCircleOutline,
      registerUserMutation,
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss();
    },
    registerUser() {
      this.registerUserMutation({         
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        birthdate: dayjs(this.birthdate).format('YYYY-MM-DD'),
        })
        .then(res => {
        })
        .catch(err => {
        })
    }

  }
});
</script>