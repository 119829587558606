module.exports = {
  translation: {
    "dgvwhsadmin": "WHS Admin",
    "home": "Start",
    "profile": "Profil",
    "login": "Einloggen",
    "logout": "Ausloggen",
    "register": "Registrieren",
    "save": "Speichern",
    "userData": "Benutzerdaten",
    "changePassword": "Kennwort ändern",
    "changeEmail": "Email ändern",
    "language": "Sprache",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "birthdate": "Geburtstag",
    "password": "Kennwort",
    "email": "Email",
    "searchplayer": "Spieler suchen",
    "searchplayerdesc": "Spielername oder DGV Spieler ID eingeben",
    "hcpichange": "HCPI ändern",
    "searchresult": "Personen",
    "history": "Kürzlich",
    "users": "Benutzer",
    "deleteUser": "Benutzer löschen",
    "deleteUserAsk": "Diesen Benutzer löschen: ",
    "addUser": "Benutzer hinzufügen",
    "saveUserSuccessful": "Benutzer erfolgreich angelegt",
    "saveUserError": "Benutzer konnte nicht angelegt werden",
    "selectPerson": "Bitte eine Person auswählen",
    "dateLabel": "Datum",
    "columnLabel": "Spalten",
    // Errors
    "dfdae65b": "Kein Benutzer mit der Email-Adresse gefunden",
    "71b72952": "Kennwort falsch",
    "date": "Datum",
    "comment": "Kommentar",
    "hcpi": "HCPI",
    "scoreDifferential": "SD",
    "score_differential_pre_pcc_9played": "SD9 v. PCC",
    "score_differential_pre_pcc_9notplayed": "SD9 (NP) v. PCC",
    "score_differential_post_pcc_9played": "SD9 n. PCC",
    "score_differential_post_pcc_9notplayed": "SD9 (NP) n. PCC",
    "adjustedGrossScore": "GBE",
    "deleteScoringRecordItemHeader": "Scoring Record Löschen",
    "deleteScoringRecordItemMessage": "Sind Sie sicher, dass Sie den Scoring Record Eintrag mit der folgenden ID löschen möchten?",
    "cancel": "Abbrechen",
    "ok": "Ok",
    "tournamentName": "Turnier Name",
    "round": "Runde",
    "tournamentClub": "Turnier Club",
    "par": "PAR",
    "courseRating": "Course Rating",
    "slopeRating": "Slope Rating",
    "playForm": "Spielform",
    "holes": "Löcher",
    "adjustedGrossScore": "GBE",
    "snpv": "SNPV",
    "playingHcpi": "Playing HCPI",
    "adjustment": "Anpassung",
    "adjustmentScoreDifferential": "Anpassung SD",
    "comment": "Kommentar",
    "ignoreExceptionalScoreAdjustment": "Exceptional Score Ignorieren",
    "resultStatus": "Ergebnis Status",
    "country": "Land",
    "tournamentId": "Turnier ID",
    "courseName": "Kurs Name",
    "courseId": "Kurs ID",
    "pcc": "PCC",
    "scoreDifferentialPostPcc": "SD nach PCC",
    "id": "ID",
    "pleaseLogin" : 'Bitte einloggen',
    "playerName" : 'Spieler Name',
    "playerId" : 'Spieler ID',
    "clubName" : 'Clubname',
    "clubId" : 'Club ID',
    "currentHCPI" : 'Aktuelles HCPI',
    "calculatesHCPI" : 'Kalkuliertes HCPI',
    "scoreDifferentialWhsApi": "SD WHS",
    "editEntries": "Felder bearbeiten",
    "resetPassword": "Neues Kennwort vergeben",
    "invalidToken": "Token ist inkorrekt",
    "passwordToShort": "Kennwort zu kurz (min. 8 Zeichen)",
    "passwordMissmatch": "Kennwörter stimmen nicht überein",
    "passwordSaved": "Kennwort wurde gespeichert",
    "passwordSavedError": "Fehler beim Speichern des Kennwort",
    "resetSent": "Der Link für den Kennwort Reset wurde Ihnen per Mail zugesachickt",
    "resetSentError": "Es ist ein Fehler aufgetreten den Kennwort Reset anzufordern",
    "provideEmail": "Bitte geben Sie Ihre Email Adresse an",
  }
}