<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title>New Password</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline" class="ion-float-right"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
        <ion-label position="stacked">Password</ion-label>
        <ion-input placeholder="" type="password" v-model="password"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Password Repeat</ion-label>
        <ion-input placeholder="" type="password" v-model="passwordRepeat"></ion-input>
      </ion-item>
      <ion-button @click="savePassword()" expand="block" color="success">Abschicken</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'
import resetPasswordMutationFile from '../graphql/resetPassword.mutation.gql'

export default defineComponent({
  name: 'RegisterDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow },
  data() {
    return {
      email: '',
      password: '',
      passwordRepeat: '',
      token: this.$route.params.token
    }
  },
  setup() {
    const { mutate: resetPasswordMutation } = useMutation(resetPasswordMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline,
      resetPasswordMutation,
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
    savePassword() {
      if (this.token == "") {
        toastController
            .create({
              message: this.$t('invalidToken'),
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        return
      }
      if (this.password.length < 8) {
        toastController
            .create({
              message: this.$t('passwordToShort'),
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        return
      }
      if (this.password != this.passwordRepeat) {
        toastController
            .create({
              message: this.$t('passwordMissmatch'),
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        return
      }

      this.resetPasswordMutation({ token: this.token, password: this.password })
      .then(res => {
        toastController
            .create({
              message: this.$t('passwordSaved'),
              duration: 2000,
              color: 'success',
            }).then(res => res.present())
          this.passwordRepeat = ""
          this.password = ""
      })
      .catch(err => {
        toastController
            .create({
              message: this.$t('passwordSavedError'),
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
      }) 
    },
  }
});
</script>