<template>
  <ion-page>
        <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Home</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <section v-if="isLoggedIn">
        <ion-card class="myCard">
            <ion-button expand="block" color="primary">{{ $t('searchplayer') }}</ion-button>
            <ion-searchbar :placeholder="$t('searchplayerdesc')" @ionBlur="findRecents(false)" @ionFocus="findRecents(true)" @ionClear="findPerson('')" @input="findPerson($event.target.value)"></ion-searchbar>
              <ion-list v-if="personsFound && personsFound.length > 0">
                <ion-list-header>
                  {{ $t('searchresult') }}
                </ion-list-header>
                <ion-item v-for="(person, i) in personsFound" :key="i" @click="addPerson(person)">
                    <ion-label style="margin-left: 5px">
                      <h2>{{ person.lastName }}</h2>
                      <p>{{ person.firstName }}</p>
                    </ion-label>
                </ion-item>
              </ion-list>

              <ion-list v-if="recentsFound && recentsFound.length > 0">
                <ion-list-header>
                  {{ $t('history') }}
                </ion-list-header>
                <ion-item v-for="(recent, i) in recentsFound" :key="i">
                      <ion-label style="margin-left: 5px" @click="selectPerson(recent)">
                        <h2>{{ recent.lastName }}</h2>
                        <p>{{ recent.firstName }}</p>
                      </ion-label>
                      <ion-icon v-if="isLoggedIn" @click="deleteRecent(recent)" slot="end" :ios="trashOutline" :md="trashOutline"></ion-icon>
                </ion-item>
              </ion-list>
        </ion-card>

      </section>
      <section v-else>
        {{ $t('pleaseLogin') }}
      </section>
    </ion-content>
  </ion-page>
</template>

<script>
import { toastController, modalController, IonTitle, IonButton, IonToolbar, IonHeader, 
IonMenuButton, IonButtons, IonGrid, IonImg, IonCol, IonRow, IonItemDivider, IonSearchbar, 
IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, 
IonNote, IonRouterOutlet, IonSplitPane, IonSelect, IonSelectOption, IonAvatar, IonFabList, 
IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useQuery, useResult} from '@vue/apollo-composable'
import findPersonQueryFile from '../graphql/findPerson.query.gql'
import Storage from "../plugins/storage.js";

export default defineComponent({
  name: 'Home',
  components: {
    IonApp, IonImg, IonAvatar, IonPage,
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
    IonSearchbar,
    IonItemDivider,
    IonGrid, 
    IonCol, 
    IonRow,
    IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, IonButtons, IonSelect, IonSelectOption,
    IonFabList, IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard
  },
  data() {
    return {
      recentsFound: [],
    }
  },
  setup() {
    const { refetch: findPersonRefetch, result: findPersonResult, query: findPersonQuery } = useQuery(findPersonQueryFile, null)

    const personsFound = useResult(findPersonResult, null, data => data.findPerson.persons)

    return {
      personsFound,
      findPersonRefetch,
    }
  },
  computed: {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
      selectedPerson : function(){ return this.$store.getters.selectedPerson},
      persons : function(){ return JSON.parse(this.$store.getters.persons)},
  },
  methods: {
    async findPerson(searchTerm) {
      if (searchTerm.length > 0)
        this.recentsFound = []
      if (searchTerm.length > 0) {
        this.findPersonRefetch({ searchTerm: searchTerm})
      }
      else {
        this.findRecents(true)
        this.findPersonRefetch({ searchTerm: ''})
        //this.enable({ searchTerm })
      }
    },
    findRecents(value) {
      if (value)
        this.recentsFound = this.persons
      else
        setTimeout(() => { this.recentsFound = [] }, 200)
      
    },
    async addPerson(person) {
      const persons = this.$store.getters.persons != null ? JSON.parse(this.$store.getters.persons) : []
      const found = persons.filter(e => e._id === person._id).length > 0
      if (!found) {
        persons.unshift(person)
        await Storage.setItem('persons', JSON.stringify(persons))
        this.$store.dispatch('getPersons')
      }
      else if (persons.findIndex(o => o._id == person._id) != 0) {
        persons.splice(persons.findIndex(o => o._id == person._id))
        persons.unshift(person)
        await Storage.setItem('persons', JSON.stringify(persons))
        this.$store.dispatch('getPersons')
      }
      await Storage.setItem('selectedPerson', JSON.stringify(person))
      this.$store.dispatch('getselectedPerson')
      this.findPersonRefetch({ searchTerm: ''})
    },
    async selectPerson(person) {
        const persons = this.$store.getters.persons != null ? JSON.parse(this.$store.getters.persons) : []
        if (persons.findIndex(o => o._id == person._id) != 0) {
          persons.splice(persons.findIndex(o => o._id == person._id), 1)
          persons.unshift(person)
          await Storage.setItem('persons', JSON.stringify(persons))
          this.$store.dispatch('getPersons')
        }

        await Storage.setItem('selectedPerson', JSON.stringify(person))
        this.$store.dispatch('getselectedPerson')
        this.recentsFound = []
    },

  },
});
</script>

<style scoped>

#chart {
  max-width: 650px;
  margin: 35px auto;
}
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}
</style>

<style lang="scss">

img{
  width:100%;
  height:100%;
}

.myCard{
  position:relative;

}

.myOverlay{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;
    bottom: 0px;
    opacity: 1;
    background: transparent;
    color: black;

}

</style>