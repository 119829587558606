<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Profile</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <section>
      <ion-card>
        <ion-card-header>
          <ion-card-title>{{ $t('userData')}}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <section>
              <ion-item>
                <ion-label>{{ $t('language') }}</ion-label>
                <ion-select :value="language" @ionChange="setLanguage($event.target.value)">
                  <ion-select-option value="de">German</ion-select-option>
                  <ion-select-option value="en">Englisch</ion-select-option>
                </ion-select>
              </ion-item>
            </section>
            <section v-if="isLoggedIn">
              <ion-item>
                <ion-label position="stacked">{{ $t('firstName') }}</ion-label>
                <ion-input placeholder="Max" type="text" v-model="firstName"></ion-input>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ $t('lastName') }}</ion-label>
                <ion-input placeholder="Mustermann" type="text" v-model="lastName"></ion-input>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ $t('birthdate') }}</ion-label>
                <ion-datetime value="1990-02-20" placeholder="Select Date" v-model="birthdate" ></ion-datetime>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ $t('profilePicture') }}</ion-label>
                <div v-if="imageUrl" class="image-wrapper" style="margin-top: 10px;">
                  <img :src="imageUrl ? imageUrl.dataUrl : null" />
                </div>
                <ion-toolbar style="margin-top: 10px;">
                  <ion-button slot="start"  expand="block" color="primary" @click="takePicture()">{{ $t('takePicture')}}</ion-button>
                  <ion-button slot="end" v-if="imageUrl" expand="block" color="primary" @click="uploadPicture()">{{ $t('upload')}}</ion-button>
                </ion-toolbar>
              </ion-item>
              <section>
                <ion-button style="margin-top: 15px;" expand="block" color="primary" @click="saveUserData()">{{ $t('save')}}</ion-button>
               </section>
            </section>
          </ion-list>
        </ion-card-content>
      </ion-card>

     <!-- <ion-card v-if="isLoggedIn">
        <ion-card-header>
          <ion-card-title>{{ $t('changePassword') }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <ion-button style="margin-top: 15px;" expand="block" color="primary" @click="requestPasswordReset()">{{ $t('passwordReset')}}</ion-button>
          </ion-list>
        </ion-card-content>
      </ion-card> -->

      <ion-card v-if="isLoggedIn">
        <ion-card-header>
          <ion-card-title>{{ $t('changeEmail') }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <ion-item>
              <ion-label position="stacked">Email</ion-label>
              <ion-input placeholder="test@test.com" type="email" v-model="email"></ion-input>
            </ion-item>
              <ion-button style="margin-top: 15px;" expand="block" color="primary" @click="saveEmail()">{{ $t('save')}}</ion-button>
          </ion-list>
        </ion-card-content>
      </ion-card>

      </section>

    </ion-content>
  </ion-page>
</template>


<script>

import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  modalController, IonLabel, IonList, IonSelectOption, IonSelect, IonItem,
  IonCardTitle, IonCardHeader, IonInput, IonDatetime, IonCardContent, IonCard,
  toastController,
} from '@ionic/vue';
import {
  defineComponent
} from 'vue';
import RegisterDialog from './Register'
import LoginDialog from './Login'

import {
  useMutation, useQuery, useResult,
} from '@vue/apollo-composable'
import Storage from "../plugins/storage.js";

import { ImageResizer, ImageResizerOptions } from '@ionic-native/image-resizer';

import {
  Plugins,
  CameraSource,
  CameraResultType,
  CameraPhoto,
} from "@capacitor/core";

const { Camera } = Plugins;
const { imageResizer } = ImageResizer;

import requestPasswordResetMutationFile from '../graphql/requestPasswordReset.mutation.gql'

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton, IonList, IonLabel, IonSelectOption, IonSelect, IonItem,
    IonCardTitle, IonCardHeader, IonInput, IonDatetime, IonCardContent, IonCard,
  },
  setup() {
    const { mutate: requestPasswordResetMutation } = useMutation(requestPasswordResetMutationFile, { fetchPolicy: 'no-cache' });
    return {
      requestPasswordResetMutation,
    }
  },
  computed: {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
      UserData : function(){ return this.$store.getters.UserData},
      selectedPerson : function(){ return this.$store.getters.selectedPerson},
  },
  data() {
    return {
      language: this.$store.getters.language,
      firstName: this.$store.getters.UserData.firstName,
      lastName: this.$store.getters.UserData.lastName,
      email: this.$store.getters.UserData.email,
      birthdate: this.$store.getters.UserData && this.$store.getters.UserData.birthdate ? this.$store.getters.UserData.birthdate : null,
      imageUrl: null
    }
  },
  mounted() {
    this.$store.dispatch('login')
  },
  methods: {
    async setLanguage(language) {
      await Storage.setItem('language', language);
      setTimeout(() => { this.$router.go() }, 400)
    },
    requestPasswordReset() {
      this.requestPasswordResetMutation({ email: this.email })
      .then(res => {
          toastController
            .create({
              message: 'Reset was sent',
              duration: 2000,
              color: 'success',
            }).then(res => res.present())
      })
      .catch(err => {
          toastController
            .create({
              message: 'Error sending reset',
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
      }) 
    },
    async takePicture() {
      try {
        const image = await Camera.getPhoto({
          quality: 100,
          allowEditing: true,
          resultType: CameraResultType.DataUrl,
          source: CameraSource.Prompt,
        });

        const dim = await getImageDimensions(image.dataUrl)
        const imageResize = await resizedataURL(image.dataUrl, dim.w, dim.h, 200)
        // image.base64_data will contain the base64 encoded result as
        // a JPEG, with the data-uri prefix added
        image.dataUrl = imageResize
        this.imageUrl = image;
        return image;

      } catch (e) {
          console.error(e)
        return e;
      }
    },
  }
});

function getImageDimensions(file) {
  return new Promise (function (resolved, rejected) {
    var i = new Image()
    i.onload = function(){
      resolved({w: i.width, h: i.height})
    };
    i.src = file
  })
}

function resizedataURL(datas, wantedWidth, wantedHeight, dWidth){
    return new Promise(async function(resolve,reject){

        // We create an image to receive the Data URI
        var img = document.createElement('img');

        // When the event "onload" is triggered we can resize the image.
        img.onload = function()
        {        
            // We create a canvas and get its context.
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');

            // crop
            var cropleftright = wantedWidth > wantedHeight ? ((wantedWidth - wantedHeight) / 2) : 0
            wantedWidth = wantedWidth > wantedHeight ? wantedHeight : wantedWidth
            var croptopbottom = wantedWidth < wantedHeight ? ((wantedHeight - wantedWidth) / 2) : 0
            wantedHeight = wantedWidth < wantedHeight ? wantedWidth : wantedHeight

            // We set the dimensions at the wanted size.
            canvas.width = dWidth;
            canvas.height = dWidth;

            // We resize the image with the canvas method drawImage();
            ctx.drawImage(this, cropleftright, croptopbottom, wantedWidth, wantedHeight, 0, 0, dWidth, dWidth);

            var dataURI = canvas.toDataURL();

            // This is the return of the Promise
            resolve(dataURI);
        };

        // We put the Data URI in the image's src attribute
        img.src = datas;

    })
}

</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}
</style>
