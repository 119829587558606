<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title>HCPI Bremse setzen</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right">Test</ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
        <ion-label position="stacked">Letzte Änderung</ion-label>
        <ion-datetime readonly v-model="scoringRecord.hcpiBreakUpdated"></ion-datetime>
        <ion-datetime readonly display-format="HH:mm" picker-format="HH:mm" v-model="scoringRecord.hcpiBreakUpdated"></ion-datetime>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">HCPI Bremse aktiv</ion-label>
        <ion-select :value="String(hcpiBreakActive)" @ionChange="hcpiBreakActive = $event.target.value">
          <ion-select-option value="true">Ja</ion-select-option>
          <ion-select-option value="false">Nein</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-button @click="setHcpiBreak()" expand="block" color="success">{{ $t('save') }}</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonDatetime, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, closeCircleOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'
import setHcpiBreakMutationFile from '../graphql/setHcpiBreakMutation.mutation.gql'

export default defineComponent({
  name: 'setHcpiBreakDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonDatetime, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonSelect, IonSelectOption },
  props: ['propsData'],
  data() {
    return {
      scoringRecord: this.propsData.scoringRecord,
      hcpiBreakActive: this.propsData.scoringRecord.hcpiBreak
    }
  },
  mounted(){
    //console.log(this.propsData)
    //console.log(this.hcpiBreakActive)
  },
  setup() {
    const { mutate: setHcpiBreakMutation } = useMutation(setHcpiBreakMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline,
      closeCircleOutline,
      setHcpiBreakMutation,
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss();
    },
    setHcpiBreak() {
      this.setHcpiBreakMutation({         
        dgvPlayerId: this.scoringRecord.playerId,
        hcpiBreakEnabled: this.hcpiBreakActive === 'true',
        })
        .then(res => {
          toastController
            .create({
              message: "HCPI Bremse erfolgreich aktualisert",
              duration: 2000,
              color: 'success'
            }).then(res => res.present())
          this.closeDialog()
        })
        .catch(err => {
          toastController
            .create({
              message: "Beim speichern der HCPI Bremse ist ein Fehler aufgetreten",
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        })
    }

  }
});
</script>