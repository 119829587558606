const Joi = require('joi');

exports.validateDateTime = (dateTime) => {
    const { error } = Joi.date().required().validate(dateTime)
    if (error) return error.message
    return true
}
//console.log(exports.validateDateTime('2020-01-01'))

exports.validateTournamentName = (tournamentName) => {
    const { error } = Joi.string().required().validate(tournamentName)
    if (error) return error.message
    return true
}
//console.log(exports.validateTournamentName(undefined))

exports.validateRound = (round) => {
    const { error } = Joi.number().integer().required().validate(round)
    if (error) return error.message
    return true
}
//console.log(validateRound("10a"))

exports.validateTournamentClub = (tournamentClub) => {
    const { error } = Joi.number().integer().min(0).max(9999).required().validate(tournamentClub)
    console.log(error)
    if (error) return error.message
    return true
}
//console.log(validateTournamentClub(12345))

exports.validatePar = (par) => {
    const { error } = Joi.number().integer().required().validate(par)
    if (error) return error.message
    return true
}
//console.log(validatePar(10))

exports.validateCourseRating = (courseRating) => {
    const { error } = Joi.number().required().validate(courseRating)
    if (error) return error.message
    return true
}
//console.log(validateCourseRating(10.5))

exports.validateSlope = (slope) => {
    const { error } = Joi.number().required().validate(slope)
    if (error) return error.message
    return true
}
//console.log(validateSlope(10.5))

exports.validatePlayForm = (playForm) => {
    const { error } = Joi.string().valid('S', 'P', 'Z', 'L', 'G', 'F', 'H').required().validate(playForm)
    if (error) return error.message
    return true
}
//console.log(validatePlayForm("Z"))

exports.validateTournamentType = (tournamentType) => {
    const { error } = Joi.number().integer().min(0).max(9).required().validate(tournamentType)
    if (error) return error.message
    return true
}
//console.log(exports.validateTournamentType(9))

exports.validateHoles = (holes) => {
    const { error } = Joi.number().integer().valid(9, 18).required().validate(holes)
    if (error) return error.message
    return true
}
//console.log(validateHoles(19))

exports.validateAdjustedGrossScore = (adjustedGrossScore) => {
    const { error } = Joi.number().integer().required().validate(adjustedGrossScore)
    if (error) return error.message
    return true
}
//console.log(validateAdjustedGrossScore(10))

exports.validateSnpv = (snpv) => {
    const { error } = Joi.number().required().validate(snpv)
    if (error) return error.message
    return true
}
//console.log(validateSnpv(10.5))

exports.validateHcpi = (hcpi) => {
    const { error } = Joi.number().required().validate(hcpi)
    if (error) return error.message
    return true
}
//console.log(validateHcpi(10.1))

exports.validatePlayingHcpi = (playingHcpi) => {
    const { error } = Joi.number().integer().required().validate(playingHcpi)
    if (error) return error.message
    return true
}
//console.log(validatePlayingHcpi(10))

exports.validateComment = (comment) => {
    const { error } = Joi.string().required().validate(comment)
    if (error) return error.message
    return true
}
//console.log(validateComment("Test Comment"))

exports.validateIgnoreExceptionalScoreAdjustment = (ignoreExceptionalScoreAdjustment) => {
    const { error } = Joi.boolean().required().validate(ignoreExceptionalScoreAdjustment)
    if (error) return error.message
    return true
}
//console.log(validateIgnoreExceptionalScoreAdjustment(true))

exports.validateResultStatus = (resultStatus) => {
    const { error } = Joi.string().valid('W', 'AK', 'NA', 'TA', 'NRA', 'NRO', 'DQA', 'DQO').required().validate(resultStatus)
    if (error) return error.message
    return true
}
//console.log(validateResultStatus('NRA'))

exports.validateCountry = (country) => {
    const { error } = Joi.number().integer().min(1).max(1000).required().validate(country)
    if (error) return error.message
    return true
}
//console.log(validateCountry(49))