<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title>Login</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline" class="ion-float-right">Test</ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
        <ion-label position="stacked">Email</ion-label>
        <ion-input placeholder="test@test.com" type="email" v-model="email"></ion-input>
      </ion-item>
        <ion-item>
        <ion-label position="stacked">Password</ion-label>
        <ion-input placeholder="" type="password" v-model="password"></ion-input>
      </ion-item>
      <ion-button @click="loginUser()" expand="block" color="success">Login</ion-button>
      <ion-button @click="requestPasswordReset()" expand="block" color="success">Password reset</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'
import loginUserMutationFile from '../graphql/loginUser.mutation.gql'
import requestPasswordResetMutationFile from '../graphql/requestPasswordReset.mutation.gql'
import Storage from "../plugins/storage.js";

export default defineComponent({
  name: 'RegisterDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow },
  data() {
    return {
      email: '',
      password: '',
    }
  },
  setup() {
    const { mutate: loginUserMutation } = useMutation(loginUserMutationFile, null , { errorPolicy: 'all'});
    const { mutate: requestPasswordResetMutation } = useMutation(requestPasswordResetMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline,
      loginUserMutation,
      requestPasswordResetMutation,
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
    loginUser() {
      this.loginUserMutation({ email: this.email, password: this.password })
      .then(async res => {
        await Storage.setItem('accessToken', res.data.loginUser.accessToken);
        await Storage.setItem('refreshToken', res.data.loginUser.refreshToken);
        this.$store.dispatch('login')
        this.closeDialog()
      })
    },
    requestPasswordReset() {
      if (this.email == "") {
          toastController
            .create({
              message: this.$t('provideEmail'),
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        return
      }
      this.requestPasswordResetMutation({ email: this.email })
      .then(res => {
          toastController
            .create({
              message: this.$t('resetSent'),
              duration: 2000,
              color: 'success',
            }).then(res => res.present())
      })
      .catch(err => {
          toastController
            .create({
              message: this.$t('resetSentError'),
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
      }) 
    },
  }
});
</script>