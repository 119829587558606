<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title v-if="scoringRecordItem">{{ scoringRecordItem.id }}</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right">Test</ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card v-if="scoringRecordPreviewResultData">
    <ion-card-header>
      <ion-card-title>WHS</ion-card-title>
    </ion-card-header>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
            <ion-item>
              <ion-label>Score Differential Pre PCC</ion-label>
              <ion-badge slot="end">{{ scoringRecordPreviewResultData.score_differential_pre_pcc }}</ion-badge>
            </ion-item>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-item>
                <ion-label>Score Differential Post PCC</ion-label>
                <ion-badge slot="end">{{ scoringRecordPreviewResultData.score_differential_post_pcc }}</ion-badge>
              </ion-item>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div>
            <ion-item>
              <ion-label>Score Differential</ion-label>
              <ion-badge slot="end">{{ scoringRecordPreviewResultData.score_differential }}</ion-badge>
            </ion-item>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-item>
                <ion-label>PCC</ion-label>
                <ion-badge slot="end">{{ scoringRecordPreviewResultData.pcc }}</ion-badge>
              </ion-item>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div>
            <ion-item>
              <ion-label>Exceptional Score</ion-label>
              <ion-badge slot="end">{{ scoringRecordPreviewResultData.exceptional_score }}</ion-badge>
            </ion-item>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-item>
                <ion-label>Exceptional Score Reduction</ion-label>
                <ion-badge slot="end">{{ scoringRecordPreviewResultData.exceptional_score_reduction }}</ion-badge>
              </ion-item>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>



    <ion-list>
      <ion-list-header>
        <ion-chip outline @click="setProStatus()">
          <ion-label>Pro Status setzen</ion-label>
        </ion-chip>
      </ion-list-header>
      <ion-item>
        <ion-label position="stacked">{{ $t('date') }}</ion-label>
        <ion-datetime v-model="scoringRecordItem.date"></ion-datetime>
        <ion-datetime display-format="HH:mm" picker-format="HH:mm" v-model="scoringRecordItem.date"></ion-datetime>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('tournamentName') }}</ion-label>
        <ion-input type="text" v-model="scoringRecordItem.tournamentName"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('round') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.round"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('tournamentClub') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.tournamentClub"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('par') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.par"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('courseRating') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.courseRating"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('slopeRating') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.slopeRating"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('playForm') }}</ion-label>
        <ion-select :value="scoringRecordItem.playForm" @ionChange="scoringRecordItem.playForm = $event.target.value">
          <!--  -->
          <ion-select-option value="S">Stableford</ion-select-option>
          <ion-select-option value="P">Gegen Par</ion-select-option>
          <ion-select-option value="Z">Zählspiel</ion-select-option>
          <ion-select-option value="L">Lochspiel</ion-select-option>
          <ion-select-option value="G">Stableford - Gegen Par / Gemischt</ion-select-option>
          <ion-select-option value="F">Freier Modus</ion-select-option>
          <ion-select-option value="H">Höchstergebis</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item disabled="true">
        <ion-label position="stacked">{{ $t('tournamentType') }}</ion-label>
        <ion-select :value="String(scoringRecordItem.tournamentType)" @ionChange="scoringRecordItem.tournamentType = $event.target.value">
          <!-- Make View Only!  -->
          <ion-select-option value="0">Änderungseintrag, Ersteintrag</ion-select-option>
          <ion-select-option value="1">Einzel</ion-select-option>
          <ion-select-option value="2">Klassischer Vierer</ion-select-option>
          <ion-select-option value="3">Vierer mit Auswahltreibschlag</ion-select-option>
          <ion-select-option value="4">Chapman Vierer</ion-select-option>
          <ion-select-option value="5">Vierball</ion-select-option>
          <ion-select-option value="6">Aggregat Vierer</ion-select-option>
          <ion-select-option value="7">Scramble</ion-select-option>
          <ion-select-option value="8">EDS</ion-select-option>
          <ion-select-option value="9">Mannschafsturnier</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('holes') }}</ion-label>
        <ion-select :value="String(scoringRecordItem.holes)" @ionChange="scoringRecordItem.holes = $event.target.value">
          <ion-select-option value="18">18</ion-select-option>
          <ion-select-option value="9">9</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item v-if="new Date(scoringRecordItem.date) > new Date('2020-11-23')">
        <ion-label position="stacked">{{ $t('adjustedGrossScore') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.adjustedGrossScore"></ion-input>
      </ion-item>
      <ion-item v-else-if="new Date(scoringRecordItem.date) < new Date('2020-11-23')">
        <ion-label position="stacked">{{ $t('snpv') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.snpv"></ion-input>
      </ion-item>
      <ion-item v-else>
        <ion-label position="stacked">Bitte ein Datum auswählen bevor SNPV oder GBE gesetzt werden können.</ion-label>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('hcpi') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.hcpi"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('playingHcpi') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.playingHcpi"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('PCC') }}</ion-label>
        <ion-input type="number" v-model="scoringRecordItem.pcc"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('comment') }}</ion-label>
        <ion-input type="text" v-model="scoringRecordItem.comment"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('ignoreExceptionalScoreAdjustment') }}</ion-label>
        <ion-select :value="String(scoringRecordItem.ignoreExceptionalScoreAdjustment)" @ionChange="scoringRecordItem.ignoreExceptionalScoreAdjustment = $event.target.value">
          <ion-select-option value="true">Ja</ion-select-option>
          <ion-select-option value="false">Nein</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('resultStatus') }}</ion-label>
        <ion-select :value="scoringRecordItem.resultStatus" @ionChange="scoringRecordItem.resultStatus = $event.target.value">
          <ion-select-option value="W">W (Handicap Wirksam)</ion-select-option>
          <ion-select-option value="AK">AK (Außer Kokurrenz)</ion-select-option>
          <ion-select-option value="NA">NA (Nicht Angetreten)</ion-select-option>
          <ion-select-option value="TA">TA (Turnierabbruch)</ion-select-option>
          <ion-select-option value="NRA">NRA (No Return Annerkannter Grund)</ion-select-option>
          <ion-select-option value="NRO">NRO (No Return Ohne Annerkannter Grund)</ion-select-option>
          <ion-select-option value="DQA">DQA (Disqualifizert Anerkanner Grund)</ion-select-option>
          <ion-select-option value="DQO">DQO (Disqualifizert Ohne Annerkannter Grund)</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('country') }}</ion-label>
        <ion-input type="text" v-model="scoringRecordItem.country"></ion-input>
      </ion-item>
      <ion-button @click="saveScoringRecordItem(scoringRecordItem)" expand="block" color="success">{{ $t('save') }}</ion-button>
      <ion-button @click="saveScoringRecordItem(true)" expand="block" color="warning">{{ $t('reset') }}</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonDatetime, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController, IonCardTitle, IonCardHeader, IonBadge, IonCard, IonChip, IonListHeader, IonSelectOption, IonSelect } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, closeCircleOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'
import addUserMutationFile from '../graphql/addUser.mutation.gql'

import * as dayjs from 'dayjs';

export default defineComponent({
  name: 'EditSCDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonDatetime, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonCardTitle, IonCardHeader, IonBadge, IonCard, IonChip, IonListHeader, IonSelectOption, IonSelect },
  props: ['propsData'],
  data() {
    return {
      scoringRecordItem: this.propsData.scoringRecordItem,
      scoringRecordPreviewResultData: this.propsData.scoringRecordPreviewResultData,
    }
  },
  setup() {
    return {
      closeOutline,
      closeCircleOutline,
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss();
    },
    saveScoringRecordItem(scoringRecordItem) {
      return modalController.dismiss(scoringRecordItem);
    },
  }
});
</script>