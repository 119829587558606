<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title>{{ $t('addUser') }}</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right">Test</ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
        <ion-label position="stacked">{{ $t('firstName') }}</ion-label>
        <ion-input placeholder="Max" type="text" v-model="firstName"></ion-input>
      </ion-item>
        <ion-item>
        <ion-label position="stacked">{{ $t('lastName') }}</ion-label>
        <ion-input placeholder="Mustermann" type="text" v-model="lastName"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('email') }}</ion-label>
        <ion-input placeholder="test@test.com" type="email" v-model="email"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('password') }}</ion-label>
        <ion-input placeholder="" type="password" v-model="password"></ion-input>
      </ion-item>
      <ion-button @click="addUser()" expand="block" color="success">{{ $t('save') }}</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonDatetime, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, closeCircleOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'
import addUserMutationFile from '../graphql/addUser.mutation.gql'

import * as dayjs from 'dayjs';

export default defineComponent({
  name: 'AddUserDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonDatetime, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    }
  },
  setup() {
    const { mutate: addUserMutation } = useMutation(addUserMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline,
      closeCircleOutline,
      addUserMutation,
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss();
    },
    addUser() {
      this.addUserMutation({         
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        })
        .then(res => {
          toastController
            .create({
              message: this.$t('saveUserSuccessful'),
              duration: 2000,
              color: 'success'
            }).then(res => res.present())
          this.closeDialog()
        })
        .catch(err => {
          toastController
            .create({
              message: this.$t('saveUserError'),
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        })
    }

  }
});
</script>