import { createApp, reactive, provide, h } from 'vue'
import App from './App.vue'
import router from './router';
import { ApolloClient, InMemoryCache, gql, from } from '@apollo/client';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { Observable } from 'apollo-link';
import jwt_decode from "jwt-decode";

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { createStore } from 'vuex'

import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { setContext } from '@apollo/client/link/context';
import { toastController } from '@ionic/vue';
import translateGlobalProperties from './plugins/translateGlobalProperties'
import translate from './plugins/translate'
import Storage from "./plugins/storage";
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import VueApexCharts from "vue3-apexcharts";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

const diplayErrorToast = (error) => {
  toastController
  .create({
    message: error,
    duration: 5000,
    color: 'danger'
  }).then(res => res.present())

}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors && graphQLErrors[0].message === 'Unauthenticated') {
      return new Observable(observer => {
        getNewToken()
          .then(refreshResponse => {  
            store.token = refreshResponse
            store.userdata = jwt_decode(refreshResponse)
            operation.setContext(({ headers = {} }) => ({
              headers: {
                // Re-add old headers
                ...headers,
                // Switch out old access token for new one
                authorization: `Bearer ${refreshResponse}`,
              }
            }));

            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            return forward(operation).subscribe(subscriber);
          })
          .catch(error => {
            // No refresh or client token available, we force user to login
            observer.error(error);
          });
      });

    } else if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        if (error.extensions.code != translate(error.extensions.code))
            diplayErrorToast(translate(error.extensions.code))
        //console.error(error)
      })
    }
  }
)

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_URI
})

const defaultOptions = { 
  watchQuery: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  },
  query: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  },
  mutate: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  }
}

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = store.getters.token;
  const environment = await Storage.getItem('environment')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      environment: environment == 'false' ? 'production' : 'development'
    }
  }
});


const links = ApolloLink.from([
  authLink,
  errorLink,
  httpLink,
]);

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: links,
  defaultOptions
})

export const store = createStore({
  state () {
    return {
      selectedPerson: '',
      token: '',
      userdata: '',
      language: 'en',
      environment: true,
      selectedPerson: null,
      persons: null,
      columsChangeHCPI: [
        { name: 'id', selected: true},
        { name: 'date', selected: true},
        { name: 'courseName', selected: false},
        { name: 'courseId', selected: false},
        { name: 'tournamentName', selected: false},
        { name: 'round', selected: false},
        { name: 'tournamentId', selected: false},
        { name: 'par', selected: false},
        { name: 'courseRating', selected: false},
        { name: 'pcc', selected: false},
        { name: 'slopeRating', selected: false},
        { name: 'holes', selected: false},
        { name: 'playForm', selected: false},
        { name: 'adjustedGrossScore', selected: false},
        { name: 'snpv', selected: false},
        { name: 'hcpi', selected: false},
        { name: 'playingHcpi', selected: false},
        { name: 'scoreDifferential', selected: true},
        { name: 'score_differential_pre_pcc_9played', selected: false},
        { name: 'score_differential_pre_pcc_9notplayed', selected: false},
        { name: 'score_differential_post_pcc_9played', selected: false},
        { name: 'score_differential_post_pcc_9notplayed', selected: false},
        { name: 'scoreDifferentialWhsApi', selected: true},
        { name: 'adjustment', selected: false},
        { name: 'adjustmentScoreDifferential', selected: false},
        { name: 'scoreDifferentialPostPcc', selected: false},
        { name: 'comment', selected: true},
        { name: 'courseHcpi', selected: false},
        { name: 'exceptionalScore', selected: false},
        { name: 'exceptionalScoreDifference', selected: false},
        { name: 'exceptionalScoreReduction', selected: false},
        { name: 'ignoreExceptionalScoreAdjustment', selected: false},
        { name: 'resultStatus', selected: false},
        { name: 'country', selected: false},
      ],
    }
  },
  actions: {
    logout({commit}){
      return new Promise(async (resolve, reject) => {
        commit('logout')
        Storage.removeItem('accessToken')
        Storage.removeItem('refreshToken')
        resolve()
      })
    },
    login({commit}){
      return new Promise((resolve, reject) => {
        commit('login')
        resolve()
      })
    },
    setLanguage({commit}){
      return new Promise((resolve, reject) => {
        commit('setLanguage')
        resolve()
      })
    },
    setEnvironment({commit}){
      return new Promise((resolve, reject) => {
        commit('setEnvironment')
        resolve()
      })
    },
    setcolumsChangeHCPI({commit}){
      return new Promise((resolve, reject) => {
        commit('setcolumsChangeHCPI')
        resolve()
      })
    },
    changecolumsChangeHCPI({commit}, value){
      return new Promise((resolve, reject) => {
        commit('changecolumsChangeHCPI', value)
        resolve()
      })
    },
    setPersons({commit}){
      return new Promise((resolve, reject) => {
        commit('setPersons')
        resolve()
      })
    },
    getPersons({commit}){
      return new Promise((resolve, reject) => {
        commit('getPersons')
        resolve()
      })
    },
    getselectedPerson({commit}){
      return new Promise((resolve, reject) => {
        commit('getselectedPerson')
        resolve()
      })
    },
    setselectedPerson({commit}, person){
      return new Promise((resolve, reject) => {
        commit('setselectedPerson', person)
        resolve()
      })
    },
    removeselectedPerson({commit}){
      return new Promise((resolve, reject) => {
        commit('removeselectedPerson')
        resolve()
      })
    },
  },
  mutations: {
    logout(state){
      state.token = ''
      state.userdata = null
    },
    async login(state){
      state.token = await Storage.getItem('accessToken') || ''
      if (state.token != "") {
        state.userdata = jwt_decode(state.token)
      }
    },
    async setLanguage(state){
      state.language = await Storage.getItem('language') || 'en'
    },
    async setEnvironment(state){
      state.environment = await Storage.getItem('environment') || true
    },
    setPersons(state){
      Storage.setItem('persons', state.persons)
    },
    async setcolumsChangeHCPI(state){
      const columns = await Storage.getItem('columsChangeHCPI')
      if (columns)
        state.columsChangeHCPI = JSON.parse(columns)
    },
    async changecolumsChangeHCPI(state, value){
      await Storage.setItem('columsChangeHCPI', JSON.stringify(value))
      state.columsChangeHCPI = value;
    },
    async getPersons(state){
      state.persons = await Storage.getItem('persons') || null
    },
    async getselectedPerson(state){
      await Storage.getItem('selectedPerson')
        .then(res => {
          state.selectedPerson = JSON.parse(res)
          return
        })
    },
    setselectedPerson(state, person){
      state.selectedPerson = person
      Storage.setItem('selectedPerson', person)
    },
    removeselectedPerson(state, person){
      state.selectedPerson = null
      Storage.removeItem('selectedPerson')
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    UserData: state => state.userdata,
    language: state => state.language,
    environment: state => state.environment,
    persons: state => state.persons,
    selectedPerson: state => state.selectedPerson,
    token: state => state.token,
    columsChangeHCPI: state => state.columsChangeHCPI
  }
})

store.dispatch('login')
store.dispatch('setLanguage')
store.dispatch('setEnvironment')
store.dispatch('getPersons')
store.dispatch('getselectedPerson')
store.dispatch('setcolumsChangeHCPI')

    
export default store
const app = createApp(
  {
    setup () {
      provide(DefaultApolloClient, apolloClient)
    },
    render() {
      return h(App)
    }
  }
).use(IonicVue)
 .use(translateGlobalProperties)
 .use(router)
 .use(store)
 .use(VueApexCharts);
  
router.isReady().then(() => {
  app.mount('#app');
  defineCustomElements(window)
});

const mutation = gql`
mutation refreshAccessToken($refreshToken: String!) {
    refreshAccessToken(input: {
        refreshToken: $refreshToken
    }) {
        accessToken
    }
}`
const getNewToken = async () => {
  const refreshToken = await Storage.getItem('refreshToken')
  const response = await apolloClient.mutate({
    mutation,
    variables: { refreshToken: refreshToken }
  })
  .then (async res => {
    const { accessToken } = res.data.refreshAccessToken;
    Storage.setItem('accessToken', accessToken)
    return accessToken;
  })
  return response
};


