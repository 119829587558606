module.exports = {
  translation: {
    "dgvwhsadmin": "WHS Admin",
    "home": "Home",
    "profile": "Profile",
    "login": "Login",
    "logout": "Logout",
    "register": "Register",
    "save": "Save",
    "userData": "User data",
    "changePassword": "Change password",
    "changeEmail": "Change Email",
    "language": "Language",
    "firstName": "First name",
    "lastName": "Last name",
    "birthdate": "Birthday",
    "password": "Password",
    "email": "Email",
    "searchplayer": "Search player",
    "searchplayerdesc": "Search for player name or DGV Spieler ID",
    "hcpichange": "Change HCPI",
    "searchresult": "Persons",
    "history": "Recent",
    "users": "Users",
    "deleteUser": "Delete user",
    "deleteUserAsk": "Delete user: ",
    "addUser": "Add user",
    "saveUserSuccessful": "User saved",
    "saveUserError": "Error saving user",
    "selectPerson": "Please select a person",
    "dateLabel": "Date",
    "columnLabel": "Spalten",
    // Errors
    "dfdae65b": "No user found with the given email address",
    "71b72952": "Wrong password",
    "date": "Date",
    "comment": "Comment",
    "hcpi": "HCPI",
    "scoreDifferential": "SD",
    "score_differential_pre_pcc_9played": "SD9 b. PCC",
    "score_differential_pre_pcc_9notplayed": "SD9 (NP) b. PCC",
    "score_differential_post_pcc_9played": "SD9 a. PCC",
    "score_differential_post_pcc_9notplayed": "SD9 (NP) a. PCC",
    "adjustedGrossScore": "GBE",
    "deleteScoringRecordItemHeader": "Delete Scoring Record",
    "deleteScoringRecordItemMessage": "Really delete entry with ID?",
    "cancel": "Cancel",
    "ok": "Ok",
    "tournamentName": "Tournament name",
    "round": "Round",
    "tournamentClub": "Tournament club",
    "par": "PAR",
    "courseRating": "Course Rating",
    "slopeRating": "Slope Rating",
    "playForm": "Playform",
    "holes": "Holes",
    "adjustedGrossScore": "GBE",
    "snpv": "SNPV",
    "playingHcpi": "Playing HCPI",
    "adjustment": "Adjustment",
    "adjustmentScoreDifferential": "Adjustment SD",
    "comment": "Comment",
    "ignoreExceptionalScoreAdjustment": "Ignore Exceptional Score",
    "resultStatus": "Result status",
    "country": "Country",
    "tournamentId": "Tournament ID",
    "courseName": "Course name",
    "courseId": "Course ID",
    "pcc": "PCC",
    "scoreDifferentialPostPcc": "SD past PCC",
    "id": "ID",
    "pleaseLogin" : 'Please login',
    "playerName" : 'Player name',
    "playerId" : 'Player ID',
    "clubName" : 'Club name',
    "clubId" : 'Club ID',
    "currentHCPI" : 'Aktuelles HCPI',
    "calculatesHCPI" : 'Kalkuliertes HCPI',
    "scoreDifferentialWhsApi": "SD WHS",
    "editEntries": "Edit entries",
    "resetPassword": "Set new password",
    "invalidToken": "Invalid token",
    "passwordToShort": "Password to short (min. 8 characters)",
    "passwordMissmatch": "Passwords do not match",
    "passwordSaved": "Password saved",
    "passwordSavedError": "Error saving password",
    "resetSent": "The password reset link was sent to your email",
    "resetSentError": "Error sending password reset link",
    "provideEmail": "Please provide your email address",
  }
}