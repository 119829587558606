<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title>SD Anpassung setzen!</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
        <ion-label position="stacked">SD Anpassung für alle Einträge</ion-label>
        <ion-input type="text" v-model="adjustment"></ion-input>
      </ion-item>
      <ion-button @click="setSDAdjustment()" expand="block" color="success">{{ $t('save') }}</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonDatetime, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, closeCircleOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'
import updateScoringRecordItemMutationFile from '../graphql/updateScoringRecordItem.mutation.gql'

export default defineComponent({
  name: 'SetSDAdjustmentDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonDatetime, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonSelect, IonSelectOption },
  props: ['propsData'],
  data() {
    return {
      scoringRecord: this.propsData.scoringRecord,
      adjustment: 1,
    }
  },
  mounted(){
    //console.log(this.propsData.scoringRecord)
  },
  setup() {
    const { mutate: updateScoringRecordItemMutation } = useMutation(updateScoringRecordItemMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline,
      closeCircleOutline,
      updateScoringRecordItemMutation,
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss();
    },
    async setSDAdjustment() {
      const promises = []
      for (const scoringRecordItem of this.scoringRecord.scoringRecordItems) {
        promises.push(this.updateScoringRecordItemMutation({
          "dgvPlayerId": this.scoringRecord.playerId,
          "scoringRecordItemId": scoringRecordItem.id,
          "date": scoringRecordItem.date,
          "tournamentName": scoringRecordItem.tournamentName,
          "tournamentType": parseInt(scoringRecordItem.tournamentType),
          "round": parseInt(scoringRecordItem.round) || undefined,
          "tournamentClub": parseInt(scoringRecordItem.tournamentClub) || undefined,
          "par": parseInt(scoringRecordItem.par),
          "courseRating": parseFloat(scoringRecordItem.courseRating),
          "slopeRating": parseInt(scoringRecordItem.slopeRating),
          "playForm": scoringRecordItem.playForm,
          "holes": parseInt(scoringRecordItem.holes),
          "adjustedGrossScore": parseInt(scoringRecordItem.adjustedGrossScore),
          "snpv": parseInt(scoringRecordItem.snpv),
          "hcpi": parseFloat(scoringRecordItem.hcpi) || undefined,
          "playingHcpi": parseInt(scoringRecordItem.playingHcpi),
          "adjustment": parseInt(scoringRecordItem.adjustment) + parseInt(this.adjustment),
          "comment": scoringRecordItem.comment,
          "ignoreExceptionalScoreAdjustment": scoringRecordItem.ignoreExceptionalScoreAdjustment === 'true',
          "resultStatus": scoringRecordItem.resultStatus,
          "country": parseInt(scoringRecordItem.country),
        }))
      }
      await Promise.all(promises)

      toastController
        .create({
          message: "SD Adjustment setzen erfolgreich aktualisert",
          duration: 2000,
          color: 'success'
        }).then(res => res.present())
      this.closeDialog()
    }

  }
});
</script>